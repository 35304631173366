import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { ActionTypes } from "../../constants";
import Like from "../../images/like.svg";
import { getFavoriteStationIds, getRegion, getSelectedStationId } from "../../reducers";
import styled, { ThemeContext } from "styled-components";
import { IconWrapper } from "./StyledPrimitives";
import {
    StyledLink,
    ColoredStationLogo,
    MonochromeStationLogo,
    selectedStationStyles,
    stationHoverStyles, StationName,
    Frequency, LikeWrapper, DoubleStationLogo, StationLogoWrapper, disabledStationStyles
} from "./StationCardPrimitives";
import { tabletMinWidth } from "../Media";
import { selectStation } from "../../actions";

const StationTextWrapper = styled.div`
    margin-top: 3em;
    text-align: center;
    ${p => p.hide ? 'display: none' : ''};
    max-width: 100%; // для IE
`;

const Station = styled.div`
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 290px;
    box-sizing: border-box;
    padding-top: 30px;
    margin-right: 22px;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    
    ${StationTextWrapper} > * , ${IconWrapper} {       
        transition: all 1s;
    }
 
    ${p => p.$enabled ? (p.isSelected ? selectedStationStyles : stationHoverStyles) : disabledStationStyles};
    
    :hover {        
        ${LikeWrapper} {
            display: flex;
        }
    }
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        height: 160px; // для страницы с частотой
        padding-top: 15px;
        
        ${StationLogoWrapper} {
            height: 100px;
            width: 100px;
        }
        
        ${DoubleStationLogo} {
            height: 40px;
            width: 40px;
        }
        
        ${StationTextWrapper} {
            margin-top: 1em;
        }
        
        ${StationName} {
            font-size: 14px;
            margin: 0;
        }
        
        ${Frequency} {
            font-size: 11px;
            margin: 0;
        }
        
        ${LikeWrapper} {
            width: 24px;
            height: 24px;
            padding: 5px;
        }
    }
`;


const StationCard = ({ station, styles = {}, innerRef = null, className = '', suf }) => {
    const dispatch = useDispatch();
    const region = useSelector(getRegion);
    const location = useLocation();
    const route = location.pathname.split('/')[ 1 ];
    let suffix = '';
    if (suf) {
        suffix = '/' + suf;
    }
    else {
        if ([ 'digital', 'favorite', 'recent', 'frequency' ].indexOf(route) !== -1) suffix = '/' + route;
        if (location.pathname.split('/')[ 3 ]) suffix = '/' + location.pathname.split('/')[ 3 ];
    }
    
    const favoriteStationIds = useSelector(getFavoriteStationIds);
    const stationId = useSelector(getSelectedStationId);
    const theme = React.useContext(ThemeContext);

    const { hideText = false } = styles;
    const isSelected = stationId === station.id;

    const onSelect = React.useCallback((e) => {
        if (e.nativeEvent.type === 'click' || e.nativeEvent.key === 'Enter') {
            dispatch(selectStation(station));
        }
    }, [isSelected, station]);

    // реализация отправки событий аналитики при наведении на станцию
    // отправка события происходит когда мышь над станцией дольше чем указанный интервал
    const [ hoverTimeout, setHoverTimeout ] = React.useState(null);
    const onMouseEnter = e => {
        setHoverTimeout(setTimeout(() => {
            dispatch({ type: ActionTypes.HOVER_STATION, payload: station.id });
        }, 500));
    }
    const onMouseLeave = e => {
        if (hoverTimeout) clearTimeout(hoverTimeout);
        setHoverTimeout(null);
        dispatch({ type: ActionTypes.HOVER_STATION, payload: false, })
    }

    const isLiked = favoriteStationIds.includes(station.id);

    return (
        <Station
            className={className}
            isSelected={isSelected}
            $enabled={ true }
            ref={innerRef}
            onClick={onSelect}
            onKeyPress={onSelect}
            tabIndex="0"
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
        >
            <StationLogoWrapper>
                <StyledLink
                    to={ "/station/" + station.path + suffix  }
                >
                    <IconWrapper />
                    <DoubleStationLogo>
                        <MonochromeStationLogo
                            url={theme.dark ? station.logoWhite : station.logoBlack}
                            type={theme.dark ? "white" : "black"}
                            alt={ theme.dark ? station.logoWhiteAlt : station.logoBlackAlt }
                        />
                        <ColoredStationLogo
                            url={station.logoColored}
                            type="colored"
                            ariaHidden={true}
                            alt={ station.logoColoredAlt }
                        />
                    </DoubleStationLogo>
                </StyledLink>
                <LikeWrapper
                    onClick={e => {
                        e.stopPropagation();
                        dispatch({ type: ActionTypes.TOGGLE_FAVORITE, payload: station.id })
                    }}
                    liked={ isLiked }
                >
                    <Like />
                </LikeWrapper>
            </StationLogoWrapper>
            <StyledLink
                to={ "/station/" + station.path + suffix  }
            >
                <StationTextWrapper hide={hideText}>
                    <StationName>{station.name}</StationName>
                    {
                        station.regionId === region.id
                        ?
                        station.frequency
                            ? <Frequency>{station.frequency} FM</Frequency>
                            : null
                        :
                        <Frequency>{ station.defaultRegionName }</Frequency>
                    }
                </StationTextWrapper>
            </StyledLink>
        </Station>
    );
};

export default React.memo(StationCard);