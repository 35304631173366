import { createMedia } from "@artsy/fresnel"

export const mobileMinWidth = 0;
export const tabletMinWidth = 768;
export const desktopMinWidth = 1366;

export const isIE = typeof window === 'object' && /trident/i.test(window.navigator.userAgent);

const ExampleAppMedia = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        desktop: 1366,
        infinity: 100000,
    },
});

// Generate CSS to be injected into the head
export const mediaStyle = ExampleAppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = ExampleAppMedia;