import React from 'react';
import VolumeOn from '../../images/volume.svg';
import VolumeOff from '../../images/volume_off.svg';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { getIsMuted, getVolume } from "../../reducers";
import { ActionTypes } from "../../constants";
import Slider from 'rc-slider';

const SliderWrapper = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    padding: 2em 0;
    height: 12em;
    width: 4em;
    left: 50%;
    transform: translateX(-50%);
    background: ${p => p.theme.dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)'};
    border-radius: 4em;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
`;

const VolumeIcon = styled.svg`
    width: 3em;
    height: 3em; // для IE
    cursor: pointer;
    margin-bottom: 1em;
    opacity: 0.5;    
`;

const Root = styled.div`
    margin-left: auto;
    position: relative;
    
    :hover {
        ${SliderWrapper} {
            visibility: visible;
            opacity: 1;
        }
        
        ${VolumeIcon} {
           opacity: 0.8;
        }
    }
`;

const Volume = () => {
    const volume = useSelector(getVolume);
    const isMuted = useSelector(getIsMuted);
    const dispatch = useDispatch();
    const theme = React.useContext(ThemeContext);

    return (
        <Root>
            <VolumeIcon
                onClick={() => dispatch({ type: ActionTypes.TOGGLE_MUTE })}
                as={isMuted ? VolumeOff : VolumeOn}
            />
            <SliderWrapper>
                <Slider
                    type="range"
                    min={0}
                    max={100}
                    step={1}
                    value={volume}
                    onChange={value => dispatch({ type: ActionTypes.SET_VOLUME, payload: value })}
                    vertical={true}
                    railStyle={theme.dark ? {
                        background: '#fff',
                        opacity: 0.5
                    } : {
                        background: '#1A1B1D',
                        opacity: 0.2,
                    }}
                    trackStyle={{ background: '#FE450E' }}
                    handleStyle={{ background: '#FE450E', border: '0.4em solid white' }}
                />
            </SliderWrapper>
        </Root>
    )
};

export default Volume;