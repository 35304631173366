import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getSearchString } from "../../reducers";
import StationList from "../reused/StationList";
import { CentredPage } from "../reused/StyledPrimitives";
import DefaultContent from "../reused/DefaultContent";
import NoResult from "../../images/noresult.svg";
import styled from "styled-components";
import { ActionTypes } from '../../constants';
import { useSearchStations } from '../pages/stationsListHooks';

const StyledNoResult = styled(NoResult)`
      width: 10em;
`;

export default ({ string = false }) => {
    const dispatch = useDispatch();
    
    // строка может быть передана в компонент только при прямом заходе
    // этот код должен выполняться только на клиенте, чтобы отобразилась поисковая строка
    useEffect(() => {
        if (string && window.self) {
            dispatch({ type: ActionTypes.START_SEARCH });
            dispatch({ type: ActionTypes.SET_SEARCH_STRING, payload: string, });
        }
    }, []);
    
    const searchString = string ? string : useSelector(getSearchString);
    const stations = useSearchStations(searchString);

    return (
        stations.length
        ?
        <StationList
            stations={ stations }
        />
        :
        <CentredPage>
            <DefaultContent
                icon={StyledNoResult}
                text="По Вашему запросу ничего не найдено"
            />
        </CentredPage>
    );
};