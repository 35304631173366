import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from '../constants';
import { getLocationSelectOpened } from '../reducers';
import { tabletMinWidth } from "./Media";

export const useAppsLinks = () => {
    const dispatch = useDispatch();

    const goToApplicationStore = (link, os) => {
        dispatch({ type : ActionTypes.APP_CLICK, payload : os, });
        window.open(link);
    }
    const onClickAndrBtn = () => {
        goToApplicationStore('https://play.google.com/store/apps/details?id=ru.radioplayer', 'android');
    }
    const onClickIosBtn = () => {
        goToApplicationStore('https://apps.apple.com/us/app/%D1%80%D0%B0%D0%B4%D0%B8%D0%BE-%D0%B5%D0%B4%D0%B8%D0%BD%D1%8B%D0%B9-%D1%80%D0%B0%D0%B4%D0%B8%D0%BE%D0%BF%D0%BB%D0%B5%D0%B5%D1%80/id1528396153', 'ios');
    }
    const onClickAppGalleryBtn = () => {
        goToApplicationStore('https://appgallery.huawei.com/app/C107704269', 'android');
    }
    const onClickRuStoreBtn = () => {
        goToApplicationStore('https://apps.rustore.ru/app/ru.radioplayer', 'android');
    }

    return { onClickAndrBtn, onClickIosBtn, onClickAppGalleryBtn, onClickRuStoreBtn };
}

export const useLocationMobileOpened = () => {
    const locationSelectOpened = useSelector(getLocationSelectOpened);
    const locationOpenedOnMobile = locationSelectOpened && window.self && window.innerWidth < ( tabletMinWidth - 1 );
    return locationOpenedOnMobile;
}