import React from 'react';
import { Header } from "../reused/StyledPrimitives";
import styled from "styled-components";
import carPlayUrl from '../../images/apple_car_play.jpg';
import androidAutoUrl from '../../images/android_auto.jpg';
import siteUrl from '../../images/site.jpg';
import GooglePlay from '../../images/google_play.svg';
import AppStore from '../../images/app_store.svg';
import AppGallery from '../../images/app_gallery.svg';
import RuStore from '../../images/ru_store.svg';
import Scrollbar from "../reused/Scrollbar";
import { useAppsLinks } from '../hooks';

const Root = styled.div`
    padding-bottom: 5em;
    padding-right: 5em;
    
    figure {
        margin: 0;
        margin-right: 4em;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;
    }

    figcaption {
        font-size: 1.5rem;
        opacity: 0.8;
        margin-top: 1em;
        text-align: center;
    }
    
    img {
        width: 100%;
        border: 1px solid gray;
        border-radius: 8px;
    }
`;

const ImageGroup = styled.div`
    display: flex;
    margin-bottom: 5em;
    padding-left: 0.5em;
`;

const H2 = styled.h2`
    font-weight: normal;
    font-size: 2rem;
    opacity: 0.8;
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
`;

const MobileAppIcon = styled.svg`
    height: 50px;
    margin-right: 2em;
    cursor: pointer;
`;

const Description = styled.div`
    max-width: 1000px;
    margin-bottom: 5em;

    p, li {
        font-family: Roboto, sans-serif;
        font-size: 18px;
        letter-spacing: 0.02em;
        line-height: 1.5;
        opacity: 0.9;
    }
`;

export default () => {
    const { onClickAndrBtn, onClickIosBtn, onClickAppGalleryBtn, onClickRuStoreBtn } = useAppsLinks();

    return (
        <Scrollbar>
            <Root>
                <Header>Как нас слушать</Header>

                <H2>Сайт</H2>
                <ImageGroup>
                    <figure>
                        <img src={siteUrl} alt="Скриншот сайта" />
                    </figure>
                </ImageGroup>

                <H2>Автомобиль</H2>
                <ImageGroup>
                    <figure>
                        <img src={carPlayUrl} alt="Скриншот из Apple CarPlay" />
                        <figcaption>Apple CarPlay</figcaption>
                    </figure>
                    <figure>
                        <img src={androidAutoUrl} alt="Скриншот из Android Auto" />
                        <figcaption>Android Auto</figcaption>
                    </figure>
                </ImageGroup>

                <H2>Мобильные приложения</H2>
                <div>
                    <MobileAppIcon as={GooglePlay} onClick={ onClickAndrBtn } />
                    <MobileAppIcon as={AppStore} onClick={ onClickIosBtn } />
                    <MobileAppIcon as={RuStore} onClick={ onClickRuStoreBtn } />
                    <MobileAppIcon as={AppGallery} onClick={ onClickAppGalleryBtn } />
                </div>
                
                <Description>                
                    <p>Прослушивание многих радиостанций вашего города теперь доступно на компьютере и на вашем смартфоне. Запускаем приложение и сразу видим список станций, это экран “ВСЕ” где списком расположены станции доступные в вашем городе. При первом запуске станции показаны в хаотичном порядке.</p>
                    <p>Выбираем из списка, станцию которую хотим послушать и кликаем по ней. Запускается воспроизведение станции и в нижней части экрана появляется плеер с кнопкой воспроизведения и информацией о том, что идет в эфире. Можно кликнуть по плееру и он развернется в полный экран, кнопки управления станут больше, появится крупная фотография артиста или передачи, также будут показана информация о том что сейчас идет в эфире, предоставлена возможность поменять радиостанцию в виде вращающейся карусели. Нажав на кнопку i появится дополнительное окно, в котором можно перейти в социальные сети текущей радиостанции и посетить официальный вебсайт, при этом воспроизведение не остановится.</p>
                    <p>На всех экранах рядом с логотипом станции или просто в списках, на главном экране “все”, плеере станции и т.д. присутствует элемент стилизованный под сердце, нажав на который станция отправится в ваш список избранного.</p>
                    <p>На главном экране также доступно поле для поиска, вы можете поискать радиостанцию по названию, жанру, категории или направлению.</p>
                    <p>При последующем запуске станции на экране “ВСЕ” будут расположены в порядке их последнего прослушивания, первая станция в списке будет та что вы слушали самой последней при прошлом запуске, те станции которые не были прослушаны будут снова выстроены в случайном порядке.</p>
                    <p>Давайте посмотрим какие экраны есть в приложении, в приложении доступны дополнительные экраны:</p>
                    <p>"Избранное" - на нем расположены только те станции которые вы отметили “сердечком”, первая та что была добавлена последней и далее в порядке добавления. Экран удобен для быстрого доступа к любимым радиостанциям.</p>
                    <p>Экран “недавнее” разделен на календарь недель и показаны станции которые вы слушали в разное время</p>
                    <p>Экран “частота” имитирует аналоговый радиоприемник с частотами в вашем городе, просто двигайте сетку и выбирайте станцию как в аналоговом радиоприемнике</p>
                    <p>Экран “настройки” стилизованный под шестеренку, позволяет увидеть в каком регионе вы находитесь, определение региона происходит по gps координатам вашего смартфона, можно выбрать предпочитаемое качество звучания, при этом приложение будет принудительно включать именного его, вы можете переключить приложение в светлую или темную тему, а также напрямую обратится с проблемой или предложением.</p>
                    <p>Приложение оптимизировано под прослушивание за рулем. Есть автомобильный режим, это специальный режим интерфейса который удобно использовать находясь за рулем автомобиля, элементы интерфейса становятся крупными, исчезает некоторое оформление. Если подключить смартфон к мультимедийной системе автомобиля через Google Auto или CarPlay от Apple то становится удобно управлять приложением через мультимедийную систему. Если ваш автомобиль оснащен bluetooth интерфейсом, то можно подключить приложение к головной системе автомобиля. Управление или через приложение и автомобильный режим в нем, либо с помощью экрана (поддерживается штатный аудиоплеер в головной системе).</p>
                    <p>Также в приложении реализованы дополнительные возможности для легкого доступа и переключения между станциями, главное это виджет на экране блокировки, когда вы можете управлять воспроизведением и переключением станций с заблокированного экрана. На виджете видно что сейчас воспроизводится в эфире прослушиваемой станции, вы можете остановить и возобновить воспроизведение, а также переключиться на следующую или предыдущую станцию.</p>
                    <p>Возможность прослушивания доступна на компьютере в виде сайта и мобильных приложений для ios и android, его также можно запустить на smart телевизорах в браузере. Функционал прослушивания на всех платформах практически идентичный. Главная задача это обеспечить удобное прослушивание со всех платформ и устройств.</p>
                    <p>Сценарии прослушивания радиостанции через Радиоплеер:</p>
                    <ul>
                        <li>дома на телевизоре, через браузер телевизора</li>
                        <li>в дороге, через мобильное приложение</li>
                        <li>при занятии спортом, через мобильное приложение</li>
                        <li>в офисе, через сайт</li>
                        <li>в машине, с помощью гугл авто и карплея</li>
                        <li>в машине, с помощью обычного блютуз соединения</li>
                    </ul>
                </Description>

            </Root>
        </Scrollbar>
    );
}