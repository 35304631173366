import React from 'react';
import MainLogo from "./reused/MainLogo";
import styled from 'styled-components';

const Root = styled.div`
    position: fixed;
    height: 100%; // для мобильных не используем 100vh
    width: 100vw;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto, sans-serif;
    font-size: 16px;
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em;
    
    svg {
        height: 100px;
        width: 500px;
        max-width: 100%;
    }
    
    strong {
        display: inline-block;
        text-align: center;
        margin-top: 1em;
    }
`;

const ErrorLog = styled.div`
    display: flex;
    flex-direction: column;  
    margin-top: 1em;
    text-align: center;
`;

export default class ErrorBoundary extends React.Component {
    state = {
        error: null,
        errorInfo: null,
        date: null,
        notLoggedMessage: '',
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
        (async () => {
            try {
                const date = new Date();
                const res = await fetch('/log-error', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        errorStack: error.stack,
                        errorInfo: errorInfo,
                        date: date,
                    })
                });

                if (res.ok) {
                    return this.setState({
                        date: date,
                    })
                } else {
                    this.setState({ notLoggedMessage: 'Не удалось сохранить ошибку в лог. Вероятно имеет место серверная ошибка.' });
                }
            } catch (e) {
                this.setState({ notLoggedMessage: 'Не удалось сохранить ошибку в лог сервера. Проверьте подключение к сети.' });
            }
        })();
    }

    render() {
        if (this.state.error) {
            return <Root>
                <Main>
                    <MainLogo />
                    <strong>Произошла непредвиденная ошибка приложения</strong>
                    <strong>Попробуйте перезагрузить страницу или <a href="/">перейти на главную</a></strong>

                    <ErrorLog>{this.state.date ?
                        <>
                            <span>Ошибка сохранена в логах сервера. Время
                                ошибки:  <strong>{this.state.date.toISOString()}</strong>
                            </span>
                            <span>
                                Если ошибка повторяется часто, напишите нам об этом (спец. форма в плеере),
                                указав время ошибки.
                            </span>
                        </>
                        :
                        this.state.notLoggedMessage ?
                            <span>{this.state.notLoggedMessage}</span>
                            : <span>Сохраняем ошибку в лог сервера ...</span>}
                    </ErrorLog>

                </Main>
            </Root>
        } else {
            return this.props.children;
        }
    }
}