import React from 'react';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getHasStationsWithFrequency } from "../../reducers";
import Frequency from "../../images/frequency.svg";
import Favorite from "../../images/favorite.svg";
import { IconControl } from "./primitives";
import { MenuItem, IconMenuItem, MainMenu } from './MainMenuStyles';
import { useLocationMobileOpened } from '../hooks';

export default () => {
    const showFrequency = useSelector(getHasStationsWithFrequency);
    const location = useLocation();
    const isOnStation = location.pathname.split('/')[ 1 ] === 'station';
    const suffix = location.pathname.split('/')[ 3 ] || false;

    const locationOpenedOnMobile = useLocationMobileOpened();

    return (
        <MainMenu className={ locationOpenedOnMobile ? "hidden" : "" }>
            <MenuItem to='/' exact className={ isOnStation && !suffix ? 'active' : '' }>FM</MenuItem>
            <MenuItem to='/digital' className={ suffix === 'digital' ? 'active' : '' }>Цифровые</MenuItem>
            {/* <MenuItem to='/recent' className={ suffix === 'recent' ? 'active' : '' }>Недавние</MenuItem> */}
            {
                showFrequency
                ?
                <IconMenuItem
                    to='/frequency'
                    className={ suffix === 'frequency' ? 'active' : '' }
                >
                    <IconControl
                        role="button"
                        aria-label="Частота"
                        as={ Frequency }
                    />
                </IconMenuItem>
                :
                null
            }
            <IconMenuItem to='/favorite' className={ suffix === 'favorite' ? 'active' : '' }>
                <IconControl
                    role="button"
                    aria-label="Избранное"
                    as={ Favorite }
                />
            </IconMenuItem>
        </MainMenu>
    )
};