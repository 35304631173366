import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getRegions, getRegion, getIsRegionUpdating } from "../../reducers";
import { Root, MainWrapper, TopWrapper, InputWrapper, Input, RegionsListWrapper, RegionsList, RegionsListItem, Refresh } from './LocationStyles';
import { ActionTypes } from "../../constants";
import SearchIcon from "../../images/search.svg";
import Scrollbar from "../reused/Scrollbar";

export default () => {
    const dispatch = useDispatch();
    const regions = useSelector(getRegions);
    const region = useSelector(getRegion);
    const isRegionUpdating = useSelector(getIsRegionUpdating);
    
    const [ search, setSearch ] = useState('');
    const searchInputRef = useRef(null);
    useEffect(() => {
        searchInputRef.current.focus();
    }, []);
    const filteredRegions = !search
        ? regions
        : regions.filter(r => r.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    
    const first = [ 1, 40, 59 ];    // Россия, МСК, СПБ
    regions.sort((a, b) => {
        if (first.includes(a.id) && first.includes(b.id)) return first.indexOf(a.id) > first.indexOf(b.id) ? 1 : -1;
        if (first.includes(a.id)) return -1;
        if (first.includes(b.id)) return 1;
        return a.name.localeCompare(b.name);
    });

    const handleRegionClick = regionId => {
        const region = regions.find(r => r.id === regionId);
        dispatch({ type: ActionTypes.CHANGE_REGION_MANUALLY, payload: region, });
    }

    const handleRefreshClick = () => {
        dispatch({ type: ActionTypes.UPDATE_REGION });
        setTimeout(() => {
            dispatch({ type: ActionTypes.CLOSE_LOCATION_SELECT, });
        }, 1000);
    }

    // для анимации
    const [ opened, setOpened ] = useState(false);
    useEffect(() => {
        setOpened(true);
    }, []);

    return (
        <Root>
            <MainWrapper className={ opened ? "opened" : "" }>
                <TopWrapper>
                    <InputWrapper>
                        <SearchIcon />
                        <Input
                            value={ search }
                            onChange={ e => setSearch(e.target.value) }
                            ref={ searchInputRef }
                        />
                    </InputWrapper>
                    <Refresh
                        title="Определить регион автоматически"
                        onClick={ handleRefreshClick }
                        $loading={ isRegionUpdating }
                    />
                </TopWrapper>
                <RegionsListWrapper>
                    <Scrollbar>
                        <RegionsList>
                            {
                                filteredRegions.map(r => (
                                    <RegionsListItem
                                        key={ r.id }
                                        onClick={ () => handleRegionClick(r.id) }
                                        className={ region && r.id === region.id ? 'active' : '' }
                                    >
                                        { r.name }
                                    </RegionsListItem>
                                ))
                            }
                        </RegionsList>
                    </Scrollbar>
                </RegionsListWrapper>
            </MainWrapper>
        </Root>
    );
}