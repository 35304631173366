import React from 'react';
import styled from "styled-components";
import Scrollbar from "../reused/Scrollbar";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../constants";
import { getStations } from "../../reducers";
import { escapeRegExp } from "../../utils";

const groupTitleHeight = 3;
const optionHeight = 2.5;

const Root = styled.div`
    position: absolute;
    left: 0;
    top: 120%;
    width: 100%;
    max-height: 1000%;
    background: ${p => p.theme.getColor(0.2)};
    background: ${p => p.theme.mainBackground};
    z-index: 1;
    border-radius: 1em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const GroupTitle = styled.div`
    background-color: ${p => p.theme.getColor(0.2)};
    height: ${groupTitleHeight}em;
    padding: 0 1em;
    text-transform: uppercase;
    opacity: 0.5;
    display: flex;
    align-items: center;
`;

const Option = styled.div`
    font-family: Roboto, sans-serif;
    background-color: ${p => p.theme.getColor(0.05)};
    height: ${optionHeight}em;
    padding: 0 1em;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${p => p.theme.color};
    
    .match {
        color: ${p => p.theme.activeColor};
        background: none;
    }
    
    .usual {
        opacity: 0.5;
    }
    
    &:hover .usual {
        opacity: 0.8;
    }
`;

const prepareOptions = () => {
    const stations = useSelector(getStations);

    const onlyUniqueNames = (name, i, self) => self.indexOf(name) === i; // удаление дубликатов

    return [{
        label: 'Станции',
        options: stations.map(station => station.name).filter(onlyUniqueNames),
    }, {
        label: 'Теги',
        options: [...stations.reduce((set, station) => {
            if (station.tags) station.tags.forEach(tag => set.add('#' + tag.toLowerCase()));
            return set;
        }, new Set())],
    }, { // Вообще частоты не были указаны в задании, можно удалить, если будут проблемы с производительностью
        label: 'Частоты',
        options: stations.map(station => station.frequency).filter(obj => obj).filter(onlyUniqueNames),
    }];
};

const renderOptions = (searchValue, onHintSelect) => {
    const dispatch = useDispatch();
    const options = prepareOptions();

    searchValue = searchValue.trim();
    const regex = new RegExp(escapeRegExp(searchValue), 'i');
    const elements = [];
    let totalHeight = 0;
    for (const group of options) {
        const groupElements = [];
        for (const option of group.options) {
            if (regex.test(option.toString())) {
                groupElements.push(
                    <Option
                        key={`option_${option}`}
                        onClick={() => onHintSelect(option) }
                    >
                        <Highlighter
                            highlightClassName="match"
                            unhighlightClassName="usual"
                            searchWords={[searchValue]}
                            autoEscape={true}
                            textToHighlight={option.toString()}
                        />
                    </Option>
                );
            }
        }
        if (groupElements.length) {
            elements.push(<GroupTitle key={`group_${group.label}`}>{group.label}</GroupTitle>, ...groupElements);
            totalHeight += groupTitleHeight + optionHeight * groupElements.length
        }
    }

    return [totalHeight, elements];
};

export default ({ searchValue, onHintSelect }) => {
    if (searchValue === null) return null;

    const [totalHeight, elements] = renderOptions(searchValue, onHintSelect);

    return (
        <Root style={{ height: totalHeight + 'em' }}>
            <Scrollbar verticalTrackCss={'right: 1em; top: 1em; bottom: 1em;'}>
                {elements}
            </Scrollbar>
        </Root>
    );
};
