import styled from "styled-components";
import CloseIcon from '../../images/close.svg';
import { tabletMinWidth, desktopMinWidth } from "../Media";

export const Root = styled.div`
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-family: Roboto, sans-serif;
    flex: 1 1 auto;
    border-radius: 5px;
    border: none;
    background-color: ${ p => p.theme.getColor(0.1) };  
    padding: .5em 1em;
    margin: 0 1em;
    position: relative;
    box-sizing: border-box;
    z-index: 8888;

    @media (min-width: ${tabletMinWidth}px) and (max-width: ${desktopMinWidth - 1}px) {
        margin: 0 1em 0 2em;
    }
    @media (min-width: ${desktopMinWidth}px) {
        margin: 0 2em;
    }
`;

export const InputWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
`;

export const Input = styled.input`
    flex: 1 1 auto;
    background-color: transparent;
    border: none;
    outline: none;
    color: ${ p => p.theme.color };
    caret-color: ${ p => p.theme.color };
    font-size: 0.875em;
    opacity: 0.8;
    caret-color: ${ p => p.theme.activeColor };
    min-width: 0;
    
    ::placeholder {
        opacity: .7;
    }
`;

export const StyledCloseIcon = styled(CloseIcon)`
    cursor:pointer;
    width: 1.5em; 
`;