import { ActionTypes } from "./constants";
import Toaster from "./components/Toaster";
import { getSelectedStationId, getRegion } from "./reducers";

export const selectStation = (station) => (dispatch, getState) => {
    const state = getState();
    const selectedStationId = getSelectedStationId(state);
    const region = getRegion(state);

    if (station.enabled) {
        if (selectedStationId === station.id && region.id === station.regionId) {
            dispatch({ type: ActionTypes.TOGGLE_PLAYING });
        } else {
            dispatch({ type: ActionTypes.SELECT_STATION, payload: station, });
        }
    } else {
        Toaster.show(`Станция "${station.name}" временно отключена`);
    }
};