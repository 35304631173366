import React from 'react';
import styled from "styled-components";
import Close from '../images/close.svg';
import { tabletMinWidth } from "./Media";

const Root = styled.div`
    position: fixed;
    margin: 1em 0;
    z-index: 100;
    background: ${p => p.theme.mainBackground};
    box-shadow: 0 0 8px ${p => p.theme.activeColor};
    border-radius: 8px;
    color: ${p => p.theme.color};
    font-size: 16px;
 
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5em 0.5em 0.5em 1em;
    opacity: ${p => p.visible ? 1 : 0};
    transition: opacity 0.3s;
    max-width: 90vw;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    overflow: hidden;
    
    @media(max-width: ${tabletMinWidth - 1}px) {
        font-size: 13px;
    }
    
    @media(max-width: 400px) {
        width: 90vw;
    }
    
    span {
        opacity: 0.8;
        flex: 1 1 auto;
    }
    
    svg {
        width: 32px;
        height: 32px;
        flex: 0 0 auto;
        margin-left: 1em;
        cursor: pointer;
        
        :hover {
            transform: scale(1.2);
        }
    }
`;

const Toast = ({ message, time, onClose }) => {
    const [visible, setVisible] = React.useState(false);
    React.useEffect(() => {
        const showTimeout = setTimeout(() => setVisible(true), 0);
        const hideTimeout = setTimeout(() => setVisible(false), time - 300);
        const closeTimeout = setTimeout(onClose, time);

        return () => {
            clearTimeout(showTimeout);
            clearTimeout(hideTimeout);
            clearTimeout(closeTimeout);
        }
    }, []);

    return (
        <Root time={time} visible={visible}>
            <span>{message}</span>
            <Close onClick={onClose} />
        </Root>
    )
};

class Toaster extends React.Component {
    state = { toast: null };

    render() {
        const { toast } = this.state;
        if (!toast) {
            return null;
        } else {
            return <Toast
                key={Date.now()}
                message={toast.message}
                time={toast.time}
                onClose={() => this.setState({ toast: null })}
            />;
        }
    }
}

const ref = React.createRef();

export default {
    Component: () => <Toaster ref={ref} />,
    show(message, time = 4000) {
        ref.current.setState({ toast: { message, time } });
    }
};
