import React from 'react';
import styled, { css, keyframes } from "styled-components";
import { tabletMinWidth } from "../Media";

export const CenteredColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const CentredPage = styled(CenteredColumn)`
    width: 100%;
    flex: 1 1 auto;
`;

export const CenteredText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 2em;
    opacity: 0.5;
    text-align: center;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: ${p => p.theme.getColor(0.05)};
    flex: 0 0 auto;
`;

export const Header = styled.div`
    text-transform: uppercase;
    font-size: 32px;
    margin: 1em 0;
    opacity: 0.8;
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        font-size: 24px; 
    }
`;

export const SocialButton = styled.a.attrs(() => ({
    target: "_blank",
    rel: "noopener noreferrer",
    'aria-label': "Ссылка на социальную сеть",
}))`  
    opacity: 0.3;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: inherit;
    text-decoration: none;

    svg {
        width: 27px;
        height: 27px;
    }
    
    :hover {
        opacity: 0.8;
    }
`;

const rotateKeyframes = keyframes`
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
`;

export const loadingAnimation = css`animation: ${rotateKeyframes} linear 0.9s infinite`;