import React from 'react';
import styled from "styled-components";
import { minorText, oneLineMixin } from "./primitives";
import { tabletMinWidth } from "../Media";

const Tags = styled.div`
    margin-top: 1em;
    display: flex;
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        display: none;
    }
`;

const Tag = styled.span`
    ${minorText};
    background: rgba(255,255,255,0.1);
    border-radius: 3px;
    margin-right: 0.4em;
    padding: 0 0.4em;
    ${oneLineMixin};
`;

export default ({ tags }) => {
    if (!tags || !tags.length) return null;

    return (
        <Tags>
            {tags.slice(0, 3).map((tag, i) => <Tag key={i}>#{tag.toLowerCase()}</Tag>)}
        </Tags>
    );
}