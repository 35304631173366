import styled, { css } from "styled-components";
import { IconWrapper } from "./StyledPrimitives";
import StationLogo from "./StationLogo";
import { desktopMinWidth } from "../Media";
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;

    &.disabled-link {
        pointer-events: none;
    }
`;

export const MonochromeStationLogo = styled(StationLogo)`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.4;
`;

export const ColoredStationLogo = styled(MonochromeStationLogo)`
    opacity: 0;
`;

export const StationName = styled.div`
    font-size: 24px;
    line-height: 22px;
    opacity: 0.4;
    text-align: center;
`;

export const Frequency = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    opacity: 0.4;
    margin-top: 0.5em;
`;

export const selectedStationStyles = css`    
    ${IconWrapper} {
        background: ${p => p.theme.dark ? 'white' : 'rgba(166,171,180,0.2)'};
        transform: scale(1.05);
    }
    
    ${MonochromeStationLogo} {
       opacity: 0;
    }
    ${ColoredStationLogo} {
        opacity: 1;
    }
    
    ${Frequency} {
        opacity: 0.5;
    }
    
    ${StationName} {
        opacity: 1;
    }
`;

export const disabledStationStyles = css`
    opacity: 0.6;
    cursor: not-allowed;
`;

export const stationHoverStyles = css`
    :hover, :focus {
        ${IconWrapper} {
            background: ${p => p.theme.dark ? 'rgba(255,255,255,0.8)' : 'rgba(166,171,180,0.2)'};
            transform: scale(1.05);
        }   
        ${StationName}, ${Frequency} {
            opacity: 0.5;
        }
        ${MonochromeStationLogo} {
           opacity: 0;
        }
        ${ColoredStationLogo} {
            opacity: 1;
        }
    }
`;

export const DoubleStationLogo = styled.div`
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
`;

export const NotLikedStyle = css`
    fill: none;
    color: ${p => p.theme.color};
    opacity: 0.5;
`;

export const LikeWrapper = styled.div`
    background: ${p => p.theme.mainBackground};
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    color: ${p => p.theme.activeColor};
    padding: 9px;
    border-radius: 100px;
    position: absolute;
    right: 0;
    bottom: 0;
    display:  ${p => p.liked ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    
    & > svg {
        transition-duration: 0s;
        flex: 1 1 auto;
       -webkit-backface-visibility: hidden; // убрать мерцание при анимации на странице частоты в Сhrome
        ${p => !p.liked ? NotLikedStyle : ''};
    }
    
    &:hover {
        svg {
            color: ${p => p.theme.activeColor};
            opacity: 1;
        }
    }
    
    @media (max-width: ${desktopMinWidth - 1}px) {
        display: flex;
    }
`;

export const StationLogoWrapper = styled.div`
    width: 160px;
    height: 160px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    
    ${IconWrapper} {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
`;
