import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Angle from '../../../images/angle.svg';
import VerticalLine from "./VerticalLine";
import { unitLength } from "./css";
import { desktopMinWidth } from "../../Media";

const Root = styled.div`
    font-size: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 0 1em;
    box-sizing: border-box;
    
    & > * {
        margin-bottom: -7em;
    }
`;

const HorizontalLine = styled.div`
    position: absolute;
    height: 1px;
    width: ${p => (p.units + 2) * unitLength}px;
    background: gray;
    opacity: 0.3;
`;

const renderVerticalLines = (stations, minFrequency, maxFrequency) => {
    const elements = [];
    for (let i = minFrequency * 10; i <= maxFrequency * 10; i++) {
        const frequency = i / 10;
        const station = stations.find(station => station.frequency === frequency);
        elements.push(<VerticalLine station={station} frequency={i / 10} key={i} />);
    }
    return elements;
};

const LeftButton = styled(Angle)`
    flex: 0 0 auto;
    width: 3em;
    height: 3em;
    cursor: pointer;
    opacity: 0.8;
    
    &[disabled] {
        opacity: 0.1;
    }
    
    @media (max-width: ${desktopMinWidth - 1}px) {
        display: none;
    }
`;

const RightButton = styled(LeftButton)`
    transform: rotate(-180deg);
`;

const Track = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`;

const TrackWrapper = styled.div`
    flex: 1 1 auto;
    overflow-x: scroll;
    height: 23em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-top: 16px; // чтобы компенсировать полосу прокрутки, точно выставляется через js
           
    @media (max-width: ${desktopMinWidth - 1}px) {
        height: 9em;
        margin-bottom: -20px;
        align-items: flex-start;
    }
`;

export default (props) => {
    const { stations } = props;

    const frequencies = stations.map(station => station.frequency).filter(f => f);
    const minFrequency = Math.floor(Math.min(...frequencies));
    const maxFrequency = Math.ceil(Math.max(...frequencies));

    const trackLength = Math.round((maxFrequency - minFrequency) * 10) / 10;

    const trackWrapperRef = useRef(null);
    const [scrollDisabled, setScrollDisabled] = useState({ left: true, right: true });

    const checkScrollState = () => {
        let left = false, right = false;
        if (trackWrapperRef.current.scrollLeft === 0) {
            left = true;
        }
        if ((trackWrapperRef.current.scrollWidth - trackWrapperRef.current.scrollLeft) <= trackWrapperRef.current.clientWidth) {
            right = true;
        }
        if (right !== scrollDisabled.right || left !== scrollDisabled.left) {
            setScrollDisabled({ left, right });
        }
    };

    useEffect(() => {
        checkScrollState();
        const scrollbarWidth = trackWrapperRef.current.offsetHeight - trackWrapperRef.current.clientHeight;
        trackWrapperRef.current.style.paddingTop = scrollbarWidth + 'px';
    }, []);

    const scrollLeft = () => {
        const track = trackWrapperRef.current;
        track.scrollTo({ top: 0, left: track.scrollLeft - track.clientWidth, behavior: 'smooth' });
    };

    const scrollRight = () => {
        const track = trackWrapperRef.current;
        track.scrollTo({ top: 0, left: track.scrollLeft + track.clientWidth, behavior: 'smooth' });
    };

    return (
        <Root>
            <LeftButton
                disabled={scrollDisabled.left}
                onClick={scrollDisabled.left ? null : scrollLeft}
            />
            <TrackWrapper ref={trackWrapperRef} onScroll={checkScrollState}>
                <Track>
                    <HorizontalLine units={trackLength * 10} />
                    {renderVerticalLines(stations, minFrequency, maxFrequency)}
                </Track>
            </TrackWrapper>
            <RightButton
                disabled={scrollDisabled.right}
                onClick={scrollDisabled.right ? null : scrollRight}
            />
        </Root>
    );
}