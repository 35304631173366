import React from 'react';
import { Header, SocialButton } from "../reused/StyledPrimitives";
import Location from '../../images/location.svg';
import Phone from '../../images/phone.svg';
import Email from '../../images/email3.svg';
import styled from 'styled-components';
import Scrollbar from "../reused/Scrollbar";

const Contact = styled.div`
    display: flex;
    margin-bottom: 3em;
`;

const TextEntry = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 18px; 
    margin-left: 2em;
    letter-spacing: 0.02em;
    opacity: 0.8;
    
    div {
        margin-bottom: 1em;
    }
`;

const Title = styled.div`
    font-size: 16px;
    opacity: .7;
`;

const Icon = styled.span`
    flex: 0 0 auto;
    svg {
        width: 32px;
        height: 32px;
    }
`;

const Link = styled.div`
    a {
        color: inherit;
        text-decoration: none;
    }
`;

export default () => {
    return (
        <Scrollbar>
            <Header>Контакты</Header>
            <Contact>
                <Icon><Location /></Icon>
                <TextEntry>
                    <Title>Адрес для корреспонденции: </Title>
                    <div>117105, г. Москва, Варшавское ш., д. 9, стр. 1А </div>
                    <div>Ассоциация «Индустриальный Радиоплеер»</div>
                </TextEntry>
            </Contact>
            <Contact>
                <Icon><Phone /></Icon>
                <TextEntry>
                    <Title>Телефон для связи: </Title>
                    <Link>
                        <a href="tel:+79037231352">+7 (903) 723 13 52</a>
                    </Link>
                </TextEntry>
            </Contact>
            <Contact>
                <Icon><Email /></Icon>
                <TextEntry>
                    <Title>Электронная почта: </Title>
                    <Link>
                        <a href="mailto:info@radioplayer.ru">info@radioplayer.ru</a>
                    </Link>
                </TextEntry>
            </Contact>
        </Scrollbar>
    )
}