import { css } from "styled-components";
import { isIE } from "../Media";

export const minorText = css`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.5;
`;

export const oneLineMixin = css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const socialButtonBaseStyles = css`
    opacity: 0;
    position: absolute;
    top: calc(50% - 16px); // для IE
    left: calc(50% - 16px); // для IE
    transition: transform 1s, opacity 1s;
`;

export const playerBackground = css`
    ${p => isIE && !p.theme.dark ? 'background: hsl(0,0%,95%)' : ''};
    
    &::before {
        content: "";
        background: url(${p => p.backgroundImage || '/noimage.svg'}) center, linear-gradient(45deg, #9ddcff, #ff882a);
        background-size: cover;
        transform: scale(1.3);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        filter: blur(50px);
        opacity: ${isIE ? 0.05 : 0.2};
    }
`;
