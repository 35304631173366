import React from 'react';
import StationList from "../reused/StationList";
import { useDigitalStations } from './stationsListHooks';

export default () => {
    const stations = useDigitalStations();
    return (
        <StationList
            stations={ stations }
        />
    );
}