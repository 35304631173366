import React from 'react';
import { Link } from 'react-router-dom';

import styled from "styled-components";
import { CentredPage } from "../reused/StyledPrimitives";
import DefaultContent from "../reused/DefaultContent";
import { StyledLink } from '../reused/StationCardPrimitives';

const SvgRoot = styled.svg`
    width: 10em;
    height: 10em;
    stroke: currentColor;
    fill: currentColor;
    
    text {
        font-family: "Courier New", monospace;
        font-size: 60px;
        text-anchor: middle;
        dominant-baseline: middle;
    }
`;



const IconComponent = () => (
    <SvgRoot viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <text x="50%" y="50%">404</text>
    </SvgRoot>
);

export default () => {
    return (
        <CentredPage>
            <DefaultContent
                icon={IconComponent}
                text="Страница не существует"
            />
            <h1>
                <StyledLink to="/">Перейти на главную</StyledLink>
            </h1>
        </CentredPage>
    );
};