import React from 'react';
import styled from "styled-components";
import Search from '../Search/Search';
import LocationSelect from '../Location/LocationSelect';
import { useSelector } from 'react-redux';
import { getLocationSelectOpened } from '../../reducers';

const Root = styled.div`
    color: white;
    height: 50px;
    font-size: 20px;
    position: relative;
`;

export default () => {
    const locationSelectOpened = useSelector(getLocationSelectOpened);

    return (
        <Root>
            {
                !locationSelectOpened
                ?
                <Search />
                :
                <LocationSelect />
            }
        </Root>
    );
};