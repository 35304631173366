/**
 * Точка входа для всех side effects.
 * То есть вся логика, не относящаяся к отображению интерфейса.
 */

import { put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from "../constants";
import { 
    getIsPlaying, getSelectedStation, getPreviousStationId, getTheme, getFavoriteStationIds, getRegion,
    getLocation, getSocialBtnClicked, getAppClicked, getHoveredStation, getFeedbackSend, getSearchString
} from '../reducers';
import Cookie from 'js-cookie';
import sendStatEvent, { sendPlayStatistics, sendStopStatistics, sendChangeStatistics, } from "./stats";
import {
    playerEventChannel,
    processPlayerEvents,
    recreatePlayer,
    setVolume,
    toggleMute,
    togglePlaying
} from "./player";
import { getDataFromLocalStorage, saveFavoriteStationIds, readOptions, saveLastSelectedStationIds, saveManuallySelectedRegion } from "./userData";
import { updateRegionData, updateStationsByRegion } from "./region";

function* init() {
    yield* getDataFromLocalStorage();
    yield* recreatePlayer();
    yield* readOptions();

    const state = yield select();
    const region = getRegion(state);
    if (!region) {
        yield* updateRegionData(false);
    }
}

function* handleChangeRegionManually(action) {
    yield* saveManuallySelectedRegion(action);
    yield* updateStationsByRegion(action.payload, true);
    yield put({ type: ActionTypes.CLOSE_LOCATION_SELECT, });
}

function* handleStationSelect() {
    yield* saveLastSelectedStationIds();
    yield* recreatePlayer();
    const state = yield select();
    if (!getIsPlaying(state)) {
        yield put({ type: ActionTypes.TOGGLE_PLAYING, payload: true });
    } else { // если не играет, статистика будет отослана в *togglePlaying(), а если играет, то надо это сделать тут
        const station = getSelectedStation(state);
        const previousStationId = getPreviousStationId(state);
        if (previousStationId) {
            yield* sendStopStatistics(previousStationId); // *stop() не вызывается перед уничтожением плеера
            yield* sendChangeStatistics(station.id, previousStationId, getIsPlaying(state));
            yield* sendPlayStatistics(); // *play() не вызывается после создания плеера
        }
    }
}

function* toggleFavorite(action) {
    const stationId = action.payload;
    const state = yield select();
    const favoriteStationIds = yield getFavoriteStationIds(state);
    if (favoriteStationIds.includes(stationId)) {
        yield* sendStatEvent('favorites_add', stationId);
    }
    else {
        yield* sendStatEvent('favorites_remove', stationId);
    }
    yield* saveFavoriteStationIds(action);
}

function* toggleTheme() {
    const state = yield select();
    const theme = getTheme(state);
    // Используем cookie, чтобы тему на сервере тоже можно было узнать
    Cookie.set('theme', theme);
    yield* sendStatEvent('change_theme', null, { ui_theme : theme, });
}

function* handleStationsUpdate() {
    yield put({ type: ActionTypes.TOGGLE_PLAYING, payload: false });
    yield* recreatePlayer();
}

function* handleChangeLocation() {
    const state = yield select();
    const location = yield getLocation(state);
    yield* sendStatEvent('view', null, { path : location, });
}

function* handleClickSocialButton() {
    const state = yield select();
    const socialBtnClicked = yield getSocialBtnClicked(state);
    if (socialBtnClicked) {
        yield* sendStatEvent('social_clicked', null, socialBtnClicked);
        yield put({ type: ActionTypes.SOCIAL_CLICK, payload: false });
    }
}

function* handleClickApp() {
    const state = yield select();
    const appClicked = yield getAppClicked(state);
    if (appClicked) {
        yield* sendStatEvent('app_link', null, { os : appClicked, });
        yield put({ type: ActionTypes.APP_CLICK, payload: false });
    }
}

function* handleSendFeedback() {
    const state = yield select();
    const feedback = yield getFeedbackSend(state);
    if (feedback) {
        yield* sendStatEvent('feedback_sended');
        yield put({ type: ActionTypes.FEEDBACK_SEND, payload: false });
    }
}

function* handleStationHovered() {
    const state = yield select();
    const hoveredStationId = yield getHoveredStation(state);
    if (hoveredStationId) yield* sendStatEvent('hover_station', null, { hoveredStationId, });
}

function* handleStartSearch() {
    yield* sendStatEvent('search');
}

function* handleSetSearchString() {
    const state = yield select();
    const string = yield getSearchString(state);
    if (string[ 0 ] === '#') yield* sendStatEvent('search_by_tag', null, { tag : string, });
}

function withErrorHandler(func) {
    return function* (action) {
        try {
            yield* func(action);
        } catch (error) {
            console.error(error);
        }
    }
}

/**
 * Слушаем события для выполнения побочных эффектов
 */
export default function* main() {
    yield takeLatest(ActionTypes.INIT, withErrorHandler(init));
    yield takeLatest(ActionTypes.TOGGLE_PLAYING, withErrorHandler(togglePlaying));
    yield takeLatest(ActionTypes.TOGGLE_MUTE, withErrorHandler(toggleMute));
    yield takeLatest(ActionTypes.SET_VOLUME, withErrorHandler(setVolume));
    yield takeEvery(playerEventChannel, withErrorHandler(processPlayerEvents));
    yield takeLatest(ActionTypes.SELECT_STATION, withErrorHandler(handleStationSelect));
    yield takeLatest(ActionTypes.TOGGLE_FAVORITE, withErrorHandler(toggleFavorite));
    yield takeLatest(ActionTypes.TOGGLE_THEME, withErrorHandler(toggleTheme));
    yield takeLatest(ActionTypes.SET_STATIONS, withErrorHandler(handleStationsUpdate));
    yield takeLatest(ActionTypes.UPDATE_REGION, withErrorHandler(updateRegionData));
    yield takeLatest(ActionTypes.CHANGE_REGION_MANUALLY, withErrorHandler(handleChangeRegionManually));
    yield takeLatest(ActionTypes.CHANGE_LOCATION, withErrorHandler(handleChangeLocation));
    yield takeLatest(ActionTypes.SOCIAL_CLICK, withErrorHandler(handleClickSocialButton));
    yield takeLatest(ActionTypes.APP_CLICK, withErrorHandler(handleClickApp));
    yield takeLatest(ActionTypes.FEEDBACK_SEND, withErrorHandler(handleSendFeedback));
    yield takeLatest(ActionTypes.HOVER_STATION, withErrorHandler(handleStationHovered));
    yield takeLatest(ActionTypes.START_SEARCH, withErrorHandler(handleStartSearch));
    yield takeLatest(ActionTypes.SET_SEARCH_STRING, withErrorHandler(handleSetSearchString));

    // Нужно только для отладки, запускается комбинацией клавиш
    // yield takeLatest("mimic_moscow", withErrorHandler(() => {
    //     return updateStationsByRegion({ id: 40, name: "Москва" });
    // }));
}