import React from 'react';
import FrequencyTrack from "./FrequencyTrack";
import styled from 'styled-components';
import StationList from "./StationList";
import { useFrequencyStations } from '../stationsListHooks';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
`;

export default () => {
    const stations = useFrequencyStations();
    
    return (
        <Root>
            <StationList stations={ stations } />
            <FrequencyTrack stations={ stations } />
        </Root>
    )
}