import React from 'react';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getIsSearch } from "../../reducers";
import MainMenu from "./MainMenu";
import AdditionalMenu from "./AdditionalMenu";
import MainLogo from "../reused/MainLogo";
import { tabletMinWidth } from "../Media";
import MenuIcon from '../../images/menu.svg';
import MobileInfoMenu from "./MobileInfoMenu";
import { Media } from "../Media";

const Root = styled.div`
    padding: 4em 2em 0 4em;
    box-sizing: border-box;
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        padding: 0em 2em 0 4em;
        font-size: 0.5em;
    }
`;

const Wrapper = styled.div`
    display: ${p => p.isSearch ? 'none' : 'flex'};
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

const TopMenu = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2em;
`;

const MenuButton = styled(MenuIcon)`
    margin-right: 3em;
    cursor: pointer;
    opacity: 0.8;
    width: 24px;
    height: 24px;
    flex: 0 0 auto;
    
    display: none;
    @media (max-width: ${tabletMinWidth - 1}px) {
        display: initial;
    }
`;

export default () => {
    const isSearch = useSelector(getIsSearch);
    const [infoMenuOpened, setInfoMenuOpened] = React.useState(false);

    return (
        <Root>
            <Media at='mobile'>
                <MobileInfoMenu
                    opened={infoMenuOpened}
                    onClose={() => setInfoMenuOpened(false)}
                />
            </Media>
            
            <Wrapper>
                <TopMenu>
                    <MenuButton onClick={() => setInfoMenuOpened(true)} />
                    <MainLogo />
                    <AdditionalMenu />
                </TopMenu>
                <MainMenu />
            </Wrapper>
        </Root>
    )
};