export default {
    config: null,
    isPlaying: false,
    location: null,
    locationSelectOpened: false,
    isWaiting: false,
    volume: 100,
    isMuted: false,
    metadata: {},
    favoriteStationIds: [],
    selectedStation: null,
    previousStationId: null,
    initialSelectedStationIds: [],
    lastSelectedStationIds: [],
    theme: 'dark',
    isSearch: false,
    searchString: '',
    stations: [],
    pages: [],
    styles: [],
    regions: [],
    regionMode: false,
    region: { id: 1, name: "Россия" },
    isRegionUpdating: false,
    socialBtnClicked: false,
    appClicked: false,
    feedbackSend: false,
    hoveredStation: false,
};