import React from 'react';
import { CentredPage, Header, loadingAnimation } from "../reused/StyledPrimitives";
import styled, { css } from 'styled-components';
import DefaultContent from "../reused/DefaultContent";
import Email from '../../images/email.svg';
import Spinner from '../../images/spinner.svg';
import Scrollbar from "../reused/Scrollbar";
import config from "../../../config";
import Toaster from "../Toaster";
import { useDispatch } from "react-redux";
import { ActionTypes } from '../../constants';

const formElementMixin = css`
    font-family: Roboto, sans-serif;
    border: 1px solid transparent;
    border-radius: 3px;
    color: inherit;
    
    &:focus {
        outline: none;
        border-color: ${p => p.theme.activeColor};
    }
`;

const Label = styled.div`
    background: none;
    opacity: 0.3;
    font-size: 0.875em;
    margin-bottom: 0.3em;
    ${formElementMixin};
`;

const Input = styled.input`
    background: ${p => p.theme.dark ? 'rgba(255,255,255,0.05)' : 'rgba(166, 171, 180, 0.1)'};
    margin-bottom: 2em;
    padding: 1em;
    font-size: 1em;
    ${formElementMixin};
`;

const TextArea = styled.textarea`
    background: ${p => p.theme.dark ? 'rgba(255,255,255,0.05)' : 'rgba(166, 171, 180, 0.1)'};
    height: 15em;
    flex: 1 1 auto;
    padding: 1em;
    margin-top: 1.5em;    
    font-size: 1em;
    
    ::placeholder {
        opacity: 0.5;  
    }
    
    ${formElementMixin};
`;

const Button = styled.button`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1em;
    background: ${p => p.theme.dark ? 'rgba(255,255,255,0.1)' : 'rgba(166,171,180,0.21)'};
    padding: 0.5em 1em;
    opacity: 0.5;
    cursor: pointer;
    text-transform: uppercase;
    
    :hover {
        opacity: 0.8;
    }
    
    :disabled {
        opacity: 0.3;
    }
    
    ${formElementMixin};
`;

const StyledSpinner = styled(Spinner)`
    margin-right: 1em;
    color: ${p => p.theme.activeColor};
    ${loadingAnimation};
`;

const ButtonWrapper = styled.div`
    margin-top: 2em;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 2em;
`;

const Form = styled.form`
    width: 30em;
    max-width: 90%;
    margin-bottom: 2em;
    align-items: stretch;
    display: flex;
    flex-direction: column;
`;

const WriteMore = styled.div`
    color: ${p => p.theme.activeColor};
    font-size: 2.25rem;
    text-transform: uppercase;
    margin-top: 1.8em;
    cursor: pointer;
`;

const Root = styled.div`
    font-size: 2rem;
`;

const Notification = styled.div`
    opacity: 0.8;
    font-size: 13px;
    margin-top: 1.5em;
    
    a {
        color: ${p => p.theme.activeColor};
    }
`;

const initialFormState = {
    name: '',
    email: '',
    message: '',
};

export default () => {
    const dispatch = useDispatch();

    const [sent, setSent] = React.useState(false);
    const [formState, setFormState] = React.useState(initialFormState);
    const [loading, setLoading] = React.useState(false);

    const onChange = React.useCallback((e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    }, [formState, setFormState]);

    const onSubmit = React.useCallback(async (e) => {
        try {
            e.preventDefault();
            setLoading(true);

            const url = `${config.apiHost}/api/web/message`;
            const res = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formState),
            });
            if (res.ok) {
                setSent(true);
                dispatch({ type : ActionTypes.FEEDBACK_SEND, payload : true, });

            } else {
                Toaster.show('Ошибка при отправке формы');
                console.warn(res);
            }
        } catch (e) {
            console.error(e);
            Toaster.show('Ошибка при отправке формы');
        } finally {
            setLoading(false);
        }
    }, [setSent, formState]);

    return sent ?
        <CentredPage>
            <DefaultContent
                icon={Email}
                text={'Сообщение успешно отправлено. Мы ответим Вам в ближайшее время'}
            />
            <WriteMore
                onClick={() => {
                    setFormState(initialFormState);
                    setSent(false);
                }}
            >Написать ещё</WriteMore>
        </CentredPage>
        :
        <Scrollbar>
            <Root>
                <Header>Написать в редакцию</Header>
                <Form onSubmit={onSubmit} onChange={onChange}>
                    <Label>Ваше имя</Label>
                    <Input maxLength="255" required name="name" type="text" />
                    <Label>E-mail</Label>
                    <Input maxLength="255" required name="email" type="email" />
                    <TextArea maxLength="10000" required name="message" placeholder="Текст сообщения" />
                    <ButtonWrapper>
                        {loading ? <StyledSpinner /> : null}
                        <Button type="submit" disabled={loading}>Отправить</Button>
                    </ButtonWrapper>
                    <Notification>
                        Нажимая на кнопку, вы даете согласие на обработку своих персональных данных и
                        соглашаетесь
                        с <a href="/policy" target="_blank" rel="noreferrer">политикой конфиденциальности</a>
                    </Notification>
                </Form>
            </Root>
        </Scrollbar>;
}