import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

// IE не может нормально обработать свойсва max-width и max-height. Если картинка квадратная - разницы нет, но если нет - то она искажается.
const isIE = typeof window === 'object' && !!window.MSInputMethodContext && !!window.document.documentMode;
const maxPrefix = isIE ? '' : 'max-';

const ImageWrapper = styled.div`{
    display: inline-flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: rgb(41,41,41);
    color: orangered;

    img, svg {
        ${maxPrefix}width: 100%;
        ${maxPrefix}height: 100%;
        flex: 1 1 auto;
    }
    
    svg {
        max-width: 90%;
    }
}`;

const SmartImage = ({ src = null, className = null, alt = 'Image', active = true }) => {
    const [isError, setIsError] = useState(!src);
    const onError = useCallback(() => setIsError(true), []);

    useEffect(() => setIsError(!src), [src]); // reset state when src changes

    return (
        <ImageWrapper className={className}>
            <img
                alt={alt}
                src={isError ? '/noimage.svg' : src}
                onError={onError}
                key={src}
                aria-hidden={!active}
            />
        </ImageWrapper>
    )
};

export default React.memo(SmartImage);