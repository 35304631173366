import React from 'react';
import styled from "styled-components";
import Logo from "../../images/logo.svg";
import { tabletMinWidth } from "../Media";
import { useHistory } from 'react-router-dom';

export const Root = styled(Logo)`
    flex: 0 0 auto;
    width: 195px;
    height: 34px; // для IE
    cursor:pointer;

    @media (max-width: ${tabletMinWidth - 1}px) {
        width: 150px;
        height: unset; // IE в мобильной версии не нужен
    }
`;

export default () => {
    const history = useHistory();
    return <Root onClick={() => history.push('/')} />;
};