import React from 'react';
import InfoIcon from "../../images/info.svg";
import styled from "styled-components";
import { NavLink, useRouteMatch } from "react-router-dom";
import { MenuIconWrapper } from "./primitives";
import { desktopMinWidth, Media } from "../Media";

const Entry = styled(NavLink)`
    font-size: 14px;
    padding: 0.6em 1.5em;
    cursor: pointer;
    background: ${p => p.theme.getColor(0.05)};
    display: block;
    text-decoration: none;
    color: ${p => p.theme.getColor(0.5)};
    
    :hover {
         background: ${p => p.theme.getColor(0.1)};
    }
    
    &.active {
        color: ${p => p.theme.activeColor};
        opacity: 1;
    }
`;

const List = styled.div`
    display: ${p => p.$opened ? 'initial' : 'none'};
    font-size: 2em;
    font-family: Robot, sans-serif;
    position: absolute;
    top: 100%;
    background: ${p => p.theme.mainBackground};
    width: 11em;
    right: 50%;
    transform: translateX(50%) translateY(1em);
    border-radius: 1em;
    z-index: 9999;
    
    :before {
        content: "";
        width: 100%;
        height: 1em;
        position: absolute;
        bottom: 100%;
    }
    
    ${Entry}:first-of-type {
       border-top-left-radius: inherit;
       border-top-right-radius: inherit;
    }
    
    ${Entry}:last-of-type {
       border-bottom-left-radius: inherit;
       border-bottom-right-radius: inherit;
    }
`;

const Root = styled.div`
    position: relative;
    
    svg {
        cursor: pointer;
        opacity: ${p => p.$active ? 1 : 0.5};
        ${p => p.$active ? 'color: ' + p.theme.activeColor : ''};
    }
    
    @media (min-width: ${desktopMinWidth}px) {    
        :hover {
            svg {
                opacity: ${p => p.$active ? 1 : 0.8};
            }
            
            ${List} {
                display: initial;
            }
        }
    }
`;

export default () => {
    const match = useRouteMatch('/info/:any');
    const [opened, setOpened] = React.useState(false);

    return (
        <Media greaterThanOrEqual="tablet">
            <Root $active={!!match}>
                <MenuIconWrapper onClick={() => setOpened(!opened)}>
                    <InfoIcon />
                </MenuIconWrapper>
                <List $opened={opened} onClick={() => setOpened(false)}>
                    <Entry to="/info/about">О проекте</Entry>
                    <Entry to="/info/contacts">Контакты</Entry>
                    <Entry to="/info/how-to-listen">Как нас слушать</Entry>
                    <Entry to="/info/write-to-us">Написать нам</Entry>
                </List>
            </Root>
        </Media>
    );
}
