import 'rc-slider/assets/index.css';

import '../vendors/font.css';

import React from 'react';
import styled, { createGlobalStyle, css, ThemeProvider } from 'styled-components';
import Main from "./Main";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../constants";
import { getTheme, getIsPlaying } from "../reducers";
import { Media, MediaContextProvider, tabletMinWidth } from "./Media"
import MobileMenu from "./Menu/MobileMenu";
import DesktopPlayer from "./Player/DesktopPlayer";
import MobilePlayer from "./Player/mobile/MobilePlayer";
import Toaster from "./Toaster";
import ErrorBoundary from "./ErrorBoundary";
import { HelmetProvider} from 'react-helmet-async';

const rootStyles = css`
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    height: 100%;
    overflow: hidden;
    min-height: 0;
    color: ${p => p.theme.color};
    font-size: 8px;
    background: ${p => p.theme.mainBackground};
`;

const DesktopRoot = styled(Media)`
    ${rootStyles};
    
    & > *:first-child {
        flex: 0 0 493px;
        box-sizing: border-box;
        max-width: 493px; // для IE
    }
    
    & > *:last-child {
        flex: 1 1 auto;
    }   
`;

const MobileRoot = styled(Media)`
    ${rootStyles};
    flex-direction: column;
`;

const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        font-size: 8px;
        height: 100%;
    }
    
    #root {
        height: 100%;
    }
`;

const withOpacity = (_color, opacity = 1) => `rgba(${_color},${opacity})`;

const darkTheme = {
    dark: true,
    withOpacity: withOpacity,
    color: 'white',
    activeColor: '#FE450E',
    mainBackground: '#1A1B1D',
    regionsPopup: '#454648',
    _color: '255,255,255',
    getColor(opacity = 1) { return withOpacity(this._color, opacity)},
};

const lightTheme = {
    ...darkTheme,
    dark: false,
    color: '#000000',
    mainBackground: '#FFFFFF',
    regionsPopup: '#ECEEF1',
    _color: '0,0,0',
};

const App = () => {
    const dispatch = useDispatch();
    const theme = useSelector(getTheme);
    const isPlaying = useSelector(getIsPlaying);

    React.useEffect(() => void dispatch({ type: ActionTypes.INIT }), []);

    /**
     * остановка воспроизведения при закрытии страницы
     * необходима для отправки события остановки в аналитику
     */
    window.onunload = () => {
      if (isPlaying) dispatch({ type: ActionTypes.TOGGLE_PLAYING, });
    };

    return (
        <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
            <HelmetProvider>
                <ErrorBoundary>
                    <GlobalStyle />
                    <MediaContextProvider>
                        <Toaster.Component />
                        <DesktopRoot at="desktop">
                            <DesktopPlayer />
                            <Main />
                        </DesktopRoot>
                        <MobileRoot lessThan="desktop">
                            <MobileMenu />
                            <Main />
                            <MobilePlayer />
                        </MobileRoot>
                    </MediaContextProvider>
                </ErrorBoundary>
            </HelmetProvider>
        </ThemeProvider>
    )
};

export default App;