import React from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from "styled-components";
import ShareIcon from '../../../images/social.svg';
import Close from "../../../images/close.svg";
import StationLogo from "../../reused/StationLogo";
import { IconWrapper, SocialButton } from "../../reused/StyledPrimitives";
import { socialButtonBaseStyles } from "../primitives";
import { getSocialLinksWithIcons } from "../utils";

const VisibleMixin = css`
    opacity: 0.5;
    transform: rotate(${p => p.percent * 360}deg) translateY(-100px) rotate(${p => -p.percent * 360}deg);
`;

const StyledSocialButton = styled(SocialButton)`
    ${socialButtonBaseStyles};      
    ${p => p.$visible ? VisibleMixin : ''};
`;

const StyledClose = styled(Close)`
    position: absolute;
    padding: 1em;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

const PopUpRoot = styled.div`
    height: 100%;
    width: 100vw; 
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    color: white;
    z-index: 2;
    
    display: flex;
    justify-content: center;
    align-items: center;

    ${IconWrapper} {
        height: 100px;
        width: 100px;
        background: #bfbfbf;
        z-index: 1;
        
        img {
            height: 70%;
            width: 70%;
        }
    }
`;

const PopUp = ({ onClose, socialLinks, logoColored }) => {
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        // В IE и Firefox не всегда иначе будет анимация
        setTimeout(() => setLoaded(true), 0);
    }, []);

    if (!window.document) {
        return <PopUpRoot suppressHydrationWarning={true} />
    }

    return createPortal(
        <PopUpRoot suppressHydrationWarning={true} onClick={onClose}>
            <StyledClose />
            <IconWrapper>
                <StationLogo url={logoColored} type="colored" />
            </IconWrapper>
            {socialLinks.map((item, i) => <StyledSocialButton
                key={i} percent={i / socialLinks.length}
                index={i}
                $visible={loaded}
                href={item.url}
                onClick={e => e.stopPropagation()}
            >
                <item.Icon />
            </StyledSocialButton>)}
        </PopUpRoot>
        , window.document.body);
};

const ShareButton = styled(ShareIcon)`
    z-index: 1;
    opacity: 0.8;
    transition: opacity 0.5s 0.5s;
    width: 26px;
    height: 26px;
    margin-right: 0.5em;
`;

const Root = styled.span`
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export default ({ station }) => {
    const [shown, setShown] = React.useState(false);
    const socialLinks = getSocialLinksWithIcons(station.socialLinks);

    if (!socialLinks || !socialLinks.length) {
        return null;
    }

    return (
        <Root>
            <ShareButton onClick={() => setShown(!shown)} />
            {shown ? <PopUp
                onClose={(() => setShown(false))}
                socialLinks={socialLinks}
                logoColored={station.logoColored}
            /> : null}
        </Root>
    );
}