/**
 * Точка входа для клиентской сборки.
 * Путь src/client.js - необходим для работы razzle.
 */

import 'core-js/features/promise';
import 'core-js/features/map';
import 'core-js/features/set';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/array/includes';
import 'core-js/features/array/find-index';
import 'core-js/features/url';
import 'core-js/features/string/includes';
import 'core-js/features/symbol';
import 'whatwg-fetch';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import App from './frontend/components/App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import configureStore from "./frontend/clientStore";
import { Provider } from "react-redux";

const store = configureStore(JSON.parse(decodeURIComponent(atob(window.__HASHSUM__))));

hydrate(
    <BrowserRouter>
        <Provider store={store}><App /></Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}
