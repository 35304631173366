/**
 * Функции для отправки событий в Google Analytics и Яндекс Метрику
 */

import { select, takeEvery } from "redux-saga/effects";
import { getRegionName, getSelectedStation, getSelectedStationId, getPreviousStationId, getStations, getTheme, getIsPlaying } from "../reducers";
import { eventChannel } from "redux-saga";
import Cookie from 'js-cookie';
import { translitString } from '../utils';

const gtag = window.gtag;
const ym = window.ym;
const globalYandexMetricaId = window.__globalYandexMetricaId;
const globalGoogleAnalyticsId = window.__globalGoogleAnalyticsId;
const initedYandexMetricas = {};

// учёт длительности прослушивания текущей станции и текущего элемента
const now = () => Math.round(new Date().getTime() / 1000);
let currentStationStartTs = null;
let currentElementStartTs = null;

export default function* sendStatEvent(event, stationId = null, params = {}) {
    try {
        const state = yield select();

        /**
         * если приходит событие окончания прослушивания элемента и длительность прослушивания текущей станции минимальна
         * значит перед этим поменялась станция, и нужно заменить её на предыдущую
         * длительность выбрана эмпирически, в теории не должна превышать время ответа сервера метаданных
         */
        if (event === 'listen' && ((now() - currentStationStartTs) <= 2)) stationId = getPreviousStationId(state);
        
        const station = stationId ? getStations(state).find(station => station.id === stationId) : getSelectedStation(state);
        const regionName = getRegionName(state);
        const theme = getTheme(state);
        const isPlaying = getIsPlaying(state);

        const eventParams = {
            identifier: `${station.name} (${regionName})`,
            regionName: regionName,
            ...params,
        };

        // учёт длительности прослушивания текущей станции и текущего элемента
        if (event === 'play_station') {
            currentStationStartTs = now();
            if (!currentElementStartTs) currentElementStartTs = now();
        }
        else if (event === 'stop_station') {
            eventParams.duration = now() - currentStationStartTs;
            currentStationStartTs = null;
        }
        else if (event === 'listen') {
            const duration = now() - currentElementStartTs;
            currentElementStartTs = isPlaying ? now() : null;
            const { artist, title } = eventParams.meta;
            // при пустых метаданных или длительности менее 5 секунд событие listen не должно отправляться
            if (!artist || !title || duration < 5) return;
            eventParams.meta = artist + ' - ' + title;
            eventParams.duration = duration;
        }

        if (event === 'hover_station') {
            const hoveredStation = getStations(state).find(station => station.id === params.hoveredStationId);
            eventParams.hoveredStationName = hoveredStation.name;
        }
        
        if (event === 'change_station') {
            const fromStation = getStations(state).find(station => station.id === params.fromStationId);
            eventParams.fromStationName = fromStation.name;
            eventParams.toStationId = station.id;
            eventParams.toStationName = station.name;
        }
        else {
            eventParams.stationId = station.id;
            eventParams.stationName = station.name;
        }

        // console.log(event);
        // console.log(eventParams);

        // ограничение длины параметров до 50 символов
        for (let key in eventParams) {
            if (typeof eventParams[ key ] === 'string' && eventParams[ key ].length > 50) {
                eventParams[ key ] = eventParams[ key ].substr(0, 50);
            }
        }

        if (globalGoogleAnalyticsId) gtag('event', event, eventParams);
        if (globalYandexMetricaId) ym(globalYandexMetricaId, 'reachGoal', event, eventParams);

        // if (globalGoogleAnalyticsId || globalYandexMetricaId) {
            // console.debug(`%c event ${event}: ${station.name} (${regionName})`, 'background: lightgreen');
        // }

        // station.googleAnalytics = station.googleAnalytics || 'G-8SP200M7Y7';        // код для станций без счётчика
        // if (station.googleAnalytics) {
            // gtag('event', event, { send_to: station.googleAnalytics });
            // console.debug(`event to ${station.googleAnalytics}`, event);
        // }

        station.yandexMetrica = station.yandexMetrica || 61861876;
        if (station.yandexMetrica) {
            if (!initedYandexMetricas[station.yandexMetrica]) {
                ym(station.yandexMetrica, 'init', {});
                initedYandexMetricas[station.yandexMetrica] = true;
                // console.debug(`%c inited station yandex metrica ${station.yandexMetrica}`, 'background: yellow');
            }
            ym(station.yandexMetrica, 'reachGoal', event);
            //console.debug(`event to ${station.yandexMetrica}`, event);
        }

        // установка свойств пользователя
        // window.__gtagParams - массив, для определения, какие свойства были установлены, а какие нет
        const gtagParams = window.__gtagParams;
        const newGtagParams = { ...gtagParams, };
        // установка станции
        if (isPlaying) {
            if (!gtagParams.current_station || gtagParams.current_station !== station.name) {
                newGtagParams.current_station = translitString(station.name).substr(0, 36);
            }
        }
        else {
            newGtagParams.current_station = 'false';
        }

        // смена / установка региона
        const cookieRegion = Cookie.get('region');
        if (gtagParams.current_region === translitString('Россия')) {
            if (cookieRegion && cookieRegion !== translitString('Россия')) {
                newGtagParams.current_region = translitString(cookieRegion).substr(0, 36);
            }
        }
        else if (gtagParams.current_region !== cookieRegion) {
            newGtagParams.current_region = translitString(cookieRegion).substr(0, 36);
        }
        // установка темы
        if (!gtagParams.ui_theme || gtagParams.ui_theme !== theme) newGtagParams.ui_theme = theme;
        // разрешение геолокации
        if (event === 'location_accept') {
            newGtagParams.location_enabled = 'true';
        }
        else if (event === 'location_decline') {
            newGtagParams.location_enabled = 'false';
            newGtagParams.current_region = translitString('Россия');
        }
        // обновление свойств пользователя, если они отличаются
        if (JSON.stringify(newGtagParams) !== JSON.stringify(window.__gtagParams)) {
            gtag('set', 'user_properties', newGtagParams);
            window.__gtagParams = newGtagParams;
            if (window.__pagePath !== window.location.pathname) {
                window.__pagePath = window.location.pathname;
                gtag('config', globalGoogleAnalyticsId, { 'page_path' : window.__pagePath, 'user_properties' : newGtagParams, });
            }
        }
    }
    catch (e) {
        console.error('Cannot send statistics');
        console.error(e);
    }
}

let listeningEventChannel;

function closeListeningEventChannel() {
    listeningEventChannel && listeningEventChannel.close();
    listeningEventChannel = null;
}

export function* sendPlayStatistics() {
    yield sendStatEvent('play_station');

    closeListeningEventChannel();
    listeningEventChannel = eventChannel(emitter => {
        const timeouts = [
            setTimeout(() => emitter('listen_5_min'), 5 * 60 * 1000),
            setTimeout(() => emitter('listen_15_min'), 15 * 60 * 1000),
            setTimeout(() => emitter('listen_30_min'), 30 * 60 * 1000),
            setTimeout(() => emitter('listen_60_min'), 60 * 60 * 1000),
        ];
        return () => {
            timeouts.forEach(timeout => clearTimeout(timeout));
            //console.debug('clear timeouts');
        };
    });

    const stationId = getSelectedStationId(yield select());
    yield takeEvery(listeningEventChannel, function* (event) {
        // делаем привязку к текущей станции, чтобы не случилось так,
        // что событие отработает сразу после смены станции
        // и listenstation30min отправится для вновь выбранной.
        yield sendStatEvent(event, stationId);
    });
}

export function* sendStopStatistics(stationId = null) {
    closeListeningEventChannel();
    yield sendStatEvent('stop_station', stationId);
}

export function* sendChangeStatistics(stationId, fromStationId, wasPlayed) {
    yield sendStatEvent('change_station', stationId, { fromStationId, wasPlayed, });
}
