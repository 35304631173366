import React from 'react';
import StationList from "../reused/StationList";
import { useFMStations } from "./stationsListHooks";

export default () => {
    const stations = useFMStations();
    
    return (
        <StationList
            stations={ stations }
        />
    );
}