import React from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { getMetadata, getSelectedStation } from "../../../reducers";
import { Cover } from "../Cover";
import MobileInfoBlock from "./MobileInfoBlock";
import { playerBackground } from "../primitives";
import PlayButton from "../PlayButton";
import { tabletMinWidth } from "../../Media";
import { getWebpCoverFromMetadata } from '../../../utils';
import { useLocationMobileOpened } from '../../hooks';

const Root = styled.div`
    display: flex;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    flex: 0 0 168px;
    align-items: center;
    contain: layout; // убрать мерцание фона при анимациях на других элементах
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        font-size: 0.5em;
        flex: 0 0 100px;
        align-items: flex-start;
        padding-top: 4em;
    }

    &.hidden {
        display: none;
    }

    ${playerBackground};
`;

const MobileCover = styled(Cover)`
    width: 130px;
    height: 130px;
    position: relative;
    margin-left: 4em;
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        width: 48px;
        height: 48px;
    }
`;

const StyledPlayButton = styled(PlayButton)`
    margin: 0 4em;
`;

export default () => {
    const station = useSelector(getSelectedStation);
    const metadata = useSelector(getMetadata);
    const cover = getWebpCoverFromMetadata(metadata);
    
    const locationOpenedOnMobile = useLocationMobileOpened();
    
    return (
        <Root
            backgroundImage={cover}
            className={ locationOpenedOnMobile ? "hidden" : "" }
        >
            <MobileCover
                src={cover}
                active={true}
            />
            <MobileInfoBlock
                station={station}
                metadata={metadata}
            />
            <StyledPlayButton />
        </Root>
    )
}