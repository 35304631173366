import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import rootReducer from './reducers';
import { ActionTypes } from "./constants";

const configureStore = (state) => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(rootReducer, state, applyMiddleware(
        thunkMiddleware,
        sagaMiddleware,
    ));
    sagaMiddleware.run(rootSaga);

    // для удобства отладки
    document.onkeydown = e => {
        (e.altKey && (e.code === 'KeyQ' || e.key === 'q') && store.dispatch({ type: ActionTypes.TOGGLE_THEME }));
        (e.altKey && (e.code === 'KeyR' || e.key === 'r') && store.dispatch({ type: 'mimic_moscow' }));
    };

    return store;
};

export default configureStore;

// if (module.hot) {
//     module.hot.accept(() => window.location.reload());
// }