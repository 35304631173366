import React from 'react';
import styled, { css } from "styled-components";
import { IconWrapper } from "../../reused/StyledPrimitives";
import { unitLength } from "./css";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedStationId } from "../../../reducers";
import StationLogo from "../../reused/StationLogo";
import { desktopMinWidth } from "../../Media";
import { selectStation } from "../../../actions";

const StationCircle = css`
    cursor: pointer;

    &:before {
        opacity: 1;
        flex: 0 0 auto;
        content: "";
        width: ${p => p.isSelected ? 10 : 5}px;
        height: ${p => p.isSelected ? 10 : 5}px;
        border:  1px solid ${p => p.isSelected ? p.theme.activeColor : 'rgba(128,128,128,0.3)'};
        border-radius: 1000px;
        background: ${p => p.isSelected ? p.theme.activeColor : p.theme.mainBackground};
        position: absolute;
        top: 50%; // для IE
        left: 50%;  // для IE
        transform: translateX(-50%) translateY(-50%);  // для IE
    }
    
    &:hover {
        background-color: ${p => p.isSelected ? p.theme.activeColor : 'rgba(128,128,128,1)'};
        &:before {
            border-color: ${p => p.isSelected ? p.theme.activeColor : 'rgba(128,128,128,1)'};
        }  
    }
`;

const SimpleVerticalLine = styled.div`
    height: ${p => p.long ? 5 : p.isSelected ? 3.5 : 2}em;
    width: 1px;
    background: ${p => p.isSelected ? p.theme.activeColor : 'rgba(128,128,128,0.3)'};
    flex: 0 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    
    ${p => p.withStation ? StationCircle : ''};
`;

const StationPreview = styled.div`
    display: none;
    z-index: 1;
    position: absolute;
    bottom: 120%;
    left: 50%;  // для IE
    transform: translateX(-50%);  // для IE
    flex-direction: column;
    align-items: center;
    width: 10em;
    flex: 0 0 auto;
    overflow: hidden;
    
    ${IconWrapper} {
        width: 5em;
        height: 5em;
        background: ${p => p.theme.getColor(0.2)};
        
        img {
            width: 70%;
            height: 70%;
        }
    }
    
    & > span {
       margin-top: 0.5em;
       text-align: center;
       opacity: 0.8;
       display: block;
       max-width: 100%; // для IE
    }
`;

const selectedTextStyles = css`
    color: ${p => p.theme.activeColor};
    opacity: 1;
    display: block;
`;

const Frequency = styled.div`
    display: none;
    font-size: 0.8em;
    opacity: 0.8;
    margin-top: 0.2em;
    position: absolute;
    top: 90%;
    left: 50%; // для IE
    transform: translateX(-50%); // для IE
        
    ${p => p.isSelected ? selectedTextStyles : ''}; 
`;

const Root = styled.div`
    font-size: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5em;
    margin-left: ${unitLength - 1}px;
    
    & > span {
        position: absolute;
        opacity: 0.5;
        bottom: -2em;
        ${p => p.isSelected ? selectedTextStyles : ''};
    }
    
    &:hover {       
        ${Frequency} {
            display: block;
        }
    }
    
    @media (min-width: ${desktopMinWidth}px) {
        &:hover {
            ${StationPreview} {
                display: flex;
            }
        }
    }
`;

const VerticalLine = ({ station, frequency }) => {
    const integer = Math.abs(frequency - Math.round(frequency)) < 0.001;
    const selectedStationId = useSelector(getSelectedStationId);
    const dispatch = useDispatch();
    const isSelected = station && selectedStationId === station.id;

    return (
        <Root isSelected={isSelected}>
            <SimpleVerticalLine
                long={integer}
                withStation={!!station}
                isSelected={isSelected}
                onClick={station ? () => dispatch(selectStation(station)) : null}
            />
            {integer ? <span>{frequency}</span> : null}
            {station ? <StationPreview>
                <IconWrapper><StationLogo url={station.logoColored} type="colored" /></IconWrapper>
                <span>{station.name}</span>
            </StationPreview> : null}
            {(station && !integer) ? <Frequency isSelected={isSelected}>{frequency}</Frequency> : null}
        </Root>
    );
};

export default React.memo(VerticalLine);