export default {
    // apiHost: 'http://192.168.70.254:1230',
    // apiHost: 'http://10.8.10.70:1230',
    // apiHost: 'http://localhost:1230',
    // apiHost: 'https://irpserver.reactor174.ru',
    apiHost: 'https://api.radioplayer.ru',
    
    siteTitle: 'Единый радиоплеер',
    
    // siteUrl: 'https://irpweb.reactor174.ru',
    siteUrl: 'https://radioplayer.ru',
}