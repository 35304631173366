import styled, { css } from "styled-components";
import SmartImage from "../reused/SmartImage";
import { desktopMinWidth } from "../Media";

const InactiveCover = css`
    position: absolute;
    left: 36px;
    top: 10px;
    background: ${p => p.theme.color};
    opacity: 0.05;
    width: 200px;
    height: 200px;
    border-radius: 3px;
    z-index: 1;
    
    @media (max-width: ${desktopMinWidth - 1}) {
        width: 130px;
        height: 130px;
    }
    
    img {
        opacity: 0;
        transition: opacity 0.5s 0.5s ease-in;
    }
`;

export const Cover = styled(SmartImage)`
    position: absolute;
    left: 0;
    top: 0;
    width: 220px;
    height: 220px;
    z-index: 2;
    border-radius: 3px;
    transition: all 1s ease;
    transform: translateZ(0); // типа оптимизация
    flex: 0 0 auto;
    
    ${p => !p.active ? InactiveCover : ''};
`;