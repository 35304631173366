import { ActionTypes } from '../constants';
import { createSelector } from 'reselect';
import initialState from './initialState';

const $ = localSelector => createSelector(state => state.general, localSelector);

export default (state = initialState, action) => {
    const payload = action.payload;
    switch (action.type) {

        case ActionTypes.UPDATE_REGION:
            return { ...state, isRegionUpdating: true };

        case ActionTypes.REGION_UPDATED:
            return { ...state, isRegionUpdating: false };

        case ActionTypes.SET_REGIONS:
            return { ...state, regions: payload };

        case ActionTypes.SET_REGION:
            return { ...state, region: payload };

        case ActionTypes.CHANGE_LOCATION:
            return { ...state, location: payload, };

        case ActionTypes.SOCIAL_CLICK:
            return { ...state, socialBtnClicked: payload, };

        case ActionTypes.APP_CLICK:
            return { ...state, appClicked: payload, };

        case ActionTypes.FEEDBACK_SEND:
            return { ...state, feedbackSend: payload, };

        case ActionTypes.HOVER_STATION:
            return { ...state, hoveredStation: payload, };

        case ActionTypes.SET_INITIAL_SELECTED_STATIONS_IDS:
            return { ...state, initialSelectedStationIds: payload, };
        case ActionTypes.SET_STATIONS:
            return {
                ...state,
                stations: action.payload,
                selectedStation: null,
                metadata: {}
            };

        case ActionTypes.SET_PAGES:
            return {
                ...state,
                pages : payload,
            }

        case ActionTypes.SET_STYLES:
            return {
                ...state,
                styles : payload,
            }

        case ActionTypes.SET_DATA_FROM_LOCAL_STORAGE:
            return {
                ...state,
                lastSelectedStationIds: action.lastSelectedStationIds,
                favoriteStationIds: action.favoriteStationIds,
                region: action.region || { id: 1, name: 'Россия' },
                ...(action.theme && { theme: action.theme }),
            };

        case ActionTypes.TOGGLE_FAVORITE:
            const index = state.favoriteStationIds.indexOf(payload);
            let newFavoriteStationIds;
            if (index === -1) {
                newFavoriteStationIds = [...state.favoriteStationIds, payload];
            } else {
                newFavoriteStationIds = [...state.favoriteStationIds];
                newFavoriteStationIds.splice(index, 1);
            }
            return {
                ...state,
                favoriteStationIds: newFavoriteStationIds,
            };

        case ActionTypes.TOGGLE_PLAYING:
            const isPlaying = payload === undefined ? !state.isPlaying : payload;
            return {
                ...state,
                isPlaying: isPlaying,
                isWaiting: isPlaying ? state.isWaiting : false,
            };

        case ActionTypes.METADATA_UPDATED:
            return {
                ...state,
                metadata: action.metadata,
            };

        case ActionTypes.TOGGLE_MUTE:
            return { ...state, isMuted: !state.isMuted, };

        case ActionTypes.SET_VOLUME:
            return {
                ...state,
                isMuted: false,
                volume: payload,
            };

        case ActionTypes.SELECT_STATION: {
            const result = {
                selectedStation: payload,
            };
            if (!action.lastSelectedStationIds) {       // передается только при ssr, чтобы убрать лишний рендеринг
                let newLastSelectedStationIds;
                const index = state.lastSelectedStationIds.indexOf(payload.id);
                if (index !== -1) {
                    newLastSelectedStationIds = [payload.id, ...state.lastSelectedStationIds];
                    newLastSelectedStationIds.splice(index + 1, 1);
                }
                else {
                    newLastSelectedStationIds = [payload.id, ...state.lastSelectedStationIds.slice(0, 29)];
                }
                result.lastSelectedStationIds = newLastSelectedStationIds;
            }
            else {
                result.lastSelectedStationIds = action.lastSelectedStationIds;
            }
            if (state.selectedStation) result.previousStationId = _getSelectedStationId(state);
            return { ...state, ...result };
        }

        case ActionTypes.TOGGLE_THEME:
            return { ...state, theme: state.theme === 'dark' ? 'light' : 'dark' };

        case ActionTypes.START_SEARCH:
            return { ...state, isSearch: true };

        case ActionTypes.END_SEARCH:
            return { ...state, isSearch: false, };

        case ActionTypes.SET_SEARCH_STRING:
            return { ...state, searchString: payload };

        case ActionTypes.SET_WAITING:
            return { ...state, isWaiting: payload };
        
        case ActionTypes.OPEN_LOCATION_SELECT:
            return { ...state, locationSelectOpened: true, };

        case ActionTypes.CLOSE_LOCATION_SELECT:
            return { ...state, locationSelectOpened: false, };

        default:
            return state;
    }
};

export const getIsRegionUpdating = $(state => state.isRegionUpdating);
export const getRegions = $(state => state.regions);
export const getRegion = $(state => state.region);
export const getRegionName = $(state => state.region ? state.region.name : 'Россия');
const _getStations = state => state.stations;
export const getStations = $(_getStations);
export const getSearchString = $(state => state.searchString);
export const getIsSearch = $(state => state.isSearch);
export const getTheme = $(state => state.theme);
const _getSelectedStationId = state => {
    const selectedStation = _getSelectedStation(state);
    return selectedStation.id;
};
export const getSelectedStationId = $(_getSelectedStationId);
export const getMetadata = $(state => state.metadata);
export const getIsPlaying = $(state => state.isPlaying);
export const getLocation = $(state => state.location);
export const getLocationSelectOpened = $(state => state.locationSelectOpened);
export const getIsWaiting = $(state => state.isWaiting);
export const getVolume = $(state => state.volume);
export const getIsMuted = $(state => state.isMuted);
export const getLastSelectedStationIds = $(state => state.lastSelectedStationIds);
export const getInitialSelectedStationIds = $(state => state.initialSelectedStationIds);
export const getLastSelectedStations = $(state => {
    const stations = _getStations(state);
    const lastSelectedStations = [];
    for (const stationId of state.lastSelectedStationIds) {
        const stationSources = stations.filter(station => station.id === stationId);
        if (stationSources.length) {
            stationSources.forEach(station => lastSelectedStations.push(station));
        }
    }
    return lastSelectedStations;
});
export const getFavoriteStationIds = $(state => state.favoriteStationIds);
export const getFavoriteStations = $(state => {
    return _getStations(state).filter(station => state.favoriteStationIds.includes(station.id));
});
const _getSelectedStation = state => {
    if (state.selectedStation) {
        return state.selectedStation;
    }
    else {
        let firstEnabledStation = state.stations.find(station => station.enabled);
        firstEnabledStation = firstEnabledStation || state.stations[0];
        const regionId = state.region ? state.region.id : false;
        if (regionId) {     // подбор станции, соответствующей региону
            const firstEnabledStationSources = state.stations
                .filter(station => station.id === firstEnabledStation.id);

            firstEnabledStationSources
                .sort((a, b) => {
                    return Number(b.regionId === regionId) - Number(a.regionId === regionId);
                });
            firstEnabledStation = firstEnabledStationSources[ 0 ];
            firstEnabledStation = firstEnabledStation || state.stations[0];

        }
        return firstEnabledStation;
    }
};
export const getSelectedStation = $(_getSelectedStation);
export const getHasStationsWithFrequency = $(state => {
    return state.stations.some(station => station.frequency);
});
export const getPreviousStationId = $(state => state.previousStationId);
export const getSocialBtnClicked = $(state => state.socialBtnClicked);
export const getAppClicked = $(state => state.appClicked);
export const getFeedbackSend = $(state => state.feedbackSend);
export const getHoveredStation = $(state => state.hoveredStation);
export const getPages = $(state => state.pages);
export const getStyles = $(state => state.styles);