import transliterationTable from './constants/transliterationTable';
import config from '../config';

const { siteTitle, siteUrl } = config;

export function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function getPageMetadata(urlString, stations, pages, styles) {
    let title = siteTitle;
    let description = siteTitle + ' - слушайте любимые интернет-радиостанции в цифровом формате';
    let keywords = 'Радио онлайн, слушать радио бесплатно, интернет-радио';
    let canonical = siteUrl + urlString;
    let notExists = false;

    const urlArr = urlString.split('/');
    const url = urlArr[ 1 ];

    if (url === 'station') {
        const stationPath = urlArr[ 2 ];
        const station = stations.find(s => s.path === stationPath);
        if (station) {
            title = station.title ? station.title : station.name + ' - ' + title;
            if (station.description) description = station.description;
            if (station.keywords) keywords = station.keywords.replace(/,/g, ', ');
            canonical = siteUrl + '/' + urlArr[ 1 ] + '/' + urlArr[ 2 ];
        }
        else {
            notExists = true;
        }
    }
    else if (url === 'style') {
        const stylePath = urlArr[ 2 ];
        const style = styles.find(s => s.path === stylePath);
        if (style) {
            title = style.title ? style.title : `Станции в стиле ${style.path} -  ${title}`;
            if (style.description) description = style.description;
            if (style.keywords) keywords = style.keywords.replace(/,/g, ', ');
        }
        else {
            notExists = true;
        }
    }
    else {
        const pagePath = urlString;
        const page = pages.find(p => p.path === pagePath);
        if (page) {
            if (page.title) title = page.title;
            if (page.description) description = page.description;
            if (page.keywords) keywords = page.keywords.replace(/,/g, ', ');
        }
        else {
            notExists = true;
        }
    }

    return { title, description, keywords, canonical, notExists, };
}

export function translitString(string) {
    const unconverted = /[0-9A-Za-z\s'.,]/;

    const converted = string.split('').map(c => {
        if (unconverted.test(c)) return c;
        return transliterationTable[ c ] || '';
    }).join('');
    return converted;
}

export function getWebpCoverFromMetadata(metadata) {
    if (!metadata.cover_webp) return metadata.cover;
    return metadata.cover.replace('.jpg', '.webp');
}