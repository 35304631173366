import Scrollbars from "react-custom-scrollbars";
import React, { useMemo } from "react";
import styled from 'styled-components';

const VerticalTrack = styled.div`
    width: 1px !important;
    ${p => p.leftSide ? 'left' : 'right'}: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    background: ${p => p.theme.dark ? '#45484D' : 'rgba(69,72,77,0.2)'};
    ${p => p.verticalTrackCss};
`;

const wrapperStyle = { flex: '1 1 auto' };

const Content = styled.div`
    overflow: hidden;
    box-sizing: border-box;
    min-height: 100%;
    ${p => p.contentCss};
`;

export const getCustomScrollHandleRenderer = (color = '#FE450E') => {
    return ({ style }) => {
        return <div
            style={{
                ...style,
                backgroundColor: color,
                opacity: 0.71,
                borderRadius: 'inherit',
                cursor: 'pointer'
            }}
        />;
    };
};

const Scrollbar = ({ children, contentCss = '', verticalTrackCss = '', autoHide = false, leftSide = false }) => {
    const customScrollHandleRenderer = useMemo(() => getCustomScrollHandleRenderer(), []);
    return (
        <Scrollbars
            universal
            autoHide={autoHide}
            style={wrapperStyle}
            renderTrackVertical={props => <VerticalTrack {...props} verticalTrackCss={verticalTrackCss} leftSide={leftSide} />}
            renderThumbVertical={customScrollHandleRenderer}
        >
            <Content contentCss={contentCss}>{children}</Content>
        </Scrollbars>
    )
};

export default Scrollbar;