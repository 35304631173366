import React from 'react';
import Stop from "../../images/stop.svg";
import Play from "../../images/play.svg";
import Spinner from "../../images/spinner.svg";
import { ActionTypes } from "../../constants";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getIsPlaying, getIsWaiting } from "../../reducers";
import { tabletMinWidth } from "../Media";
import { loadingAnimation } from "../reused/StyledPrimitives";

const StyledPlayButton = styled(Play)`
    opacity: 0.6;
    width: 100%;
    height: 100%;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: ${p => p.theme.activeColor};
    ${loadingAnimation}; // В IE не работает svg анимация, поэтому везде заменили на css
`;

const Root = styled.div`
    position: relative;
    flex: 0 0 auto;
    width: 64px;
    height: 64px;
    outline: none;
    cursor: pointer;
    contain: paint; // чтобы убрать мерцание при hover в мобильной версии
    transform: translateZ(0); // чтобы убрать мерцание при hover в планшетной версии в IE
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        width: 52px;
        height: 52px;
    }
    
    :hover, :focus {
        ${StyledPlayButton}   {
            opacity: 1;
        }  
    }
`;

export default ({ className = '' }) => {
    const dispatch = useDispatch();
    const isPlaying = useSelector(getIsPlaying);
    const isWaiting = useSelector(getIsWaiting);

    return (
        <Root
            role="button"
            tabIndex="0"
            aria-label={isPlaying ? 'Остановить проигрывание' : 'Запустить проигрывание'}
            className={className}
            onClick={() => dispatch({ type: ActionTypes.TOGGLE_PLAYING })}
        >
            <StyledPlayButton as={isPlaying ? Stop : Play} />
            {isWaiting ? <StyledSpinner /> : null}
        </Root>
    )
};