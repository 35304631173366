import React from 'react';
import StationList from "../reused/StationList";
import { CentredPage } from "../reused/StyledPrimitives";
import DefaultContent from "../reused/DefaultContent";
import Love from "../../images/love.svg";
import styled from "styled-components";
import { useFavoriteStations } from './stationsListHooks';

const StyledLove = styled(Love)`
    width: 10em;
`;

export default () => {
    const stations = useFavoriteStations();

    return (
        stations.length
        ?
        <StationList stations={ stations } />
        :
        <CentredPage>
            <DefaultContent
                icon={StyledLove}
                text="Здесь будут отображаться радиостанции, которые вам понравились"
            />
        </CentredPage>
    );
};