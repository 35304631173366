import React from 'react';
import styled from "styled-components";
import Like from "../../images/like.svg";
import { useSelector } from "react-redux";
import { getFavoriteStationIds } from "../../reducers";
import StationLogo from "../reused/StationLogo";
import { minorText, oneLineMixin } from "./primitives";
import Tags from "./Tags";
import StationSocialButtons from "./StationSocialButtons";

const Root = styled.div`
    display: flex;
    align-items: center;
    margin-top: auto;
    height: 80px;
`;

const StationName = styled.div`
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    opacity: 0.8;
    margin-right: 1em;
    ${oneLineMixin}
`;

const Frequency = styled.div`
    ${minorText};
    margin-top: 0.5em;
`;

const StationLogoWrapper = styled.div`
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background: ${p => p.theme.getColor(0.2)};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 0 0 auto;
`;

const StationLogoIcon = styled(StationLogo)`
    width: 40px;
    height: 40px;
    opacity: 0.8;
`;

const StyledLike = styled(Like)`
    color: ${p => p.theme.activeColor};
    position: absolute;
    right: 0;
    bottom: 0;
    height: 19px;
    width: 19px;
`;

const InfoWrapper = styled.div`
    margin-left: 2em;
    flex: 1 1 0;
    min-width: 0;
    overflow: hidden; // для IE, для ellipsis тегов и имени станции
`;

export default ({ station }) => {
    const favoriteStationIds = useSelector(getFavoriteStationIds);

    return (
        <Root>
            <StationLogoWrapper>
                <StationLogoIcon url={station.logoWhite} type="white" />
                {favoriteStationIds.includes(station.id) ? <StyledLike /> : null}
            </StationLogoWrapper>

            <InfoWrapper>
                <StationName>{station.name}</StationName>
                {station.frequency ? <Frequency>{station.frequency} FM</Frequency> : null}
                <Tags tags={station.tags} />
            </InfoWrapper>
            <StationSocialButtons station={station} />
        </Root>
    )
}