import React from 'react';
import styled from 'styled-components';
import { CenteredColumn, CenteredText, IconWrapper } from "./StyledPrimitives";
import { tabletMinWidth } from "../Media";

const Root = styled(CenteredColumn)`
   font-size: 8px;
   
    ${IconWrapper} {
        width: 30em;
        height: 30em;
        
        & > svg {
            width: 10em;
            height: 10em;
            opacity: 0.8;
        }
    }
    
    ${CenteredText} {
        max-width: 20em;
        margin-top: 2em;
    }
    
    @media (max-width: ${tabletMinWidth - 1}px) {        
        ${IconWrapper} {
            width: 20em;
            height: 20em;
            
            & > svg {
                width: 6em;
                height: 6em;
            }
        }
        
        ${CenteredText} {
            font-size: 14px;
        }
    }  
`;

export default ({ icon: Icon, text }) => {
    return (
        <Root css="font-size: 1rem">
            <IconWrapper><Icon /></IconWrapper>
            <CenteredText style={{}}>{text}</CenteredText>
        </Root>
    );
}