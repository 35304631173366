import React from 'react';
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import ShareIcon from '../../images/social.svg';
import { SocialButton } from "../reused/StyledPrimitives";
import { socialButtonBaseStyles } from "./primitives";
import { getSocialLinksWithIcons } from "./utils";
import { ActionTypes } from "../../constants";

const VisibleMixin = css`
    opacity: 0.3;
    transform: rotate(${p => p.percent * 360}deg) translateY(-50px) rotate(${p => -p.percent * 360}deg);
    contain: layout;
    pointer-events: auto;
    //-webkit-backface-visibility: hidden; // убрать мерцание при анимации в Сhrome
`;

const StyledSocialButton = styled(SocialButton)`
    ${socialButtonBaseStyles};
    pointer-events: none; // чтобы нельзя было навести курсор на край невидимой иконки
    ${p => p.$visible ? VisibleMixin : ''};
`;

const ShareButton = styled(ShareIcon)`
    z-index: 1;
    opacity: 0.3;
    transition: opacity 0.5s 0.5s;
    width: 26px;
    height: 26px;
    
    ${p => p.$visible ? `
        opacity: 0;
        transition: opacity 0.5s;
    ` : ''};
`;

const Root = styled.div`
    position: relative;
    width: 120px;
    height: 120px;
    z-index: 2;
    cursor: pointer;
    display: flex; 
    justify-content: center;
    align-items: center; 
    margin-right: -3em;
    margin-left: auto;
    flex: 0 0 auto;
    contain: layout;
    
    svg {
        width: 26px;
        height: 26px;
    }
`;

export default ({ station }) => {
    const socialLinks = getSocialLinksWithIcons(station.socialLinks);
    const [hover, setHover] = React.useState(false);
    const dispatch = useDispatch();

    const onClickSocialLink = e => {
        if (e.type === 'mousedown') {
            if (e.button !== 1) return;
        }
        let link = e.target;
        while (link.tagName !== 'A') link = link.parentNode;
        const type = link.dataset.type;
        const url = link.href;
        dispatch({ type : ActionTypes.SOCIAL_CLICK, payload : { type, url, }, })
    }

    if (!socialLinks || !socialLinks.length) {
        return null;
    }

    return (
        <Root onMouseLeave={() => setHover(false)}>
            <ShareButton
                onMouseOver={() => setHover(true)}
                $visible={hover}
            />
            {socialLinks.map((item, i) => {
                return (
                    <StyledSocialButton
                        key={i}
                        percent={i / socialLinks.length}
                        index={i}
                        $visible={hover}
                        href={item.url}
                        data-type={ item.type }
                        onClick={ onClickSocialLink }
                        onMouseDown={ onClickSocialLink }
                    >
                        <item.Icon />
                    </StyledSocialButton>
                );
            })}
        </Root>
    );
}