import React from 'react';
import styled, { keyframes, css } from "styled-components";
import { isIE } from "../Media"

const runningLine = keyframes`
    from {
        transform: translateX(0);
    }
    to {
         transform: translateX(-50%);
    }
`;

const Root = styled.div`
    position: relative;
    overflow: hidden;
    height: 1.2em;
    
    --left-shift: 0.5em;
    
    ${p => p.animated ? `
        margin-left: calc(var(--left-shift) * -1);
        mask-image: linear-gradient(to right, transparent, calc(var(--left-shift) * 0.8), black var(--left-shift) 90%, transparent);
    ` : ''};
`;

const animation = css`
    margin-left: var(--left-shift);
    animation: ${p => p.animationDuration}s 5s linear ${runningLine};
    
    span {
        padding-right: 2em;       
    }
`;

const Text = styled.div`
    position: absolute;
    top: 0;
    white-space: nowrap;
    line-height: 1.2;
    ${p => p.animationDuration ? animation : ''};
    mix-blend-mode: hard-light;
`;

const restartAnimation = (e) => {
    e.target.style.animation = 'none';
    e.target.offsetWidth; // вызвать reflow
    e.target.style.animation = null;
};

const Ticker = ({ text, className = '' }) => {
    const [animationDuration, setAnimationDuration] = React.useState(null);
    const [key, setKey] = React.useState(1);
    const wrapperRef = React.useRef(null);
    const textRef = React.useRef(null);

    React.useEffect(() => {
        const wrapperWidth = wrapperRef.current.offsetWidth;
        const wrapperHeight = wrapperRef.current.offsetHeight;
        const textWidth = textRef.current.offsetWidth;

        if (textWidth > wrapperWidth) {
            const time = textWidth / wrapperHeight;
            setAnimationDuration(time);
        }
    }, [text]);

    return (
        <Root
            className={className}
            animated={!!animationDuration}
            ref={wrapperRef}
        >
            <Text
                ref={textRef}
                animationDuration={animationDuration}
                onAnimationEnd={isIE ? () => setKey(key + 1) : restartAnimation}
                key={key}
            >
                <span>{text}</span>
                {animationDuration ? <span>{text}</span> : null}
            </Text>
        </Root>
    )
};

export default (props) => <Ticker {...props} key={props.text} />;
