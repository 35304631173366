import React, { useCallback, useEffect, useState } from 'react';

export default React.memo(({ url, className = '', type = 'white', ariaHidden = false, alt = "Логотип станции", }) => {
    const [isError, setIsError] = useState(!url);
    const onError = useCallback(() => setIsError(true), []);

    useEffect(() => setIsError(!url), [url]); // reset state when src changes

    return (
        <img
            className={className}
            src={isError ? `/logos/nologo_${type}.svg` : url}
            alt={ alt }
            onError={onError}
            key={url}
            aria-hidden={ariaHidden}
        />
    );
});