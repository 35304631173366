import { useSelector } from "react-redux";
import { getStations, getRegion, getInitialSelectedStationIds, getLastSelectedStationIds, getFavoriteStations, getFavoriteStationIds } from "../../reducers";
import { escapeRegExp } from "../../utils";

const isSourceFederal = source => source.regionId === 1; // источник является федеральным

// группировка источников в станции
function groupSourcesInStations(sources) {
    const stations = {};
    const stationsIds = [];
    sources.forEach(source => {
        if (!stations[ source.id ]) {
            stations[ source.id ] = [];
            stationsIds.push(source.id);
        }
        stations[ source.id ].push(source);
    });
    return { stations, stationsIds };
}

const useFmSources = () => {
    const stations = useSelector(getStations);
    const fmSources = stations.filter(s => !s.digital);
    return fmSources;
}

const useDigitalSources = () => {
    const stations = useSelector(getStations);
    const digitalSources = stations.filter(s => s.digital);
    return digitalSources;
}

export const useFMStations = () => {
    const region = useSelector(getRegion);
    // используется список недавних станций на момент загрузки страницы
    const initialSelectedStationsIds = useSelector(getInitialSelectedStationIds);
    
    const fmSources = useFmSources();
    const { stations, stationsIds } = groupSourcesInStations(fmSources);
    const fmStations = stationsIds
        .map(stationId => {     // выбираются только источники текущего региона и федеральные
            const station = stations[ stationId ];
            const currentSource = station.find(src => src.regionId === region.id);
            if (currentSource) return currentSource;
            const federalSource = station.find(src => isSourceFederal(src));
            if (federalSource) return federalSource;
            return null;
        })
        .filter(s => s !== null);
    
    const initialSelectedStations = fmStations.filter(s => initialSelectedStationsIds.includes(s.id));
    initialSelectedStations.sort((a, b) => initialSelectedStationsIds.indexOf(a.id) - initialSelectedStationsIds.indexOf(b.id));
    const anyStations = fmStations.filter(s => !initialSelectedStationsIds.includes(s.id));
    const currentFmStations = anyStations.filter(s => !isSourceFederal(s) && s.regionId === region.id);
    const federalFMStations = anyStations.filter(s => isSourceFederal(s));
    
    return [ ...initialSelectedStations, ...currentFmStations, ...federalFMStations, ];
}

export const useDigitalStations = () => {
    const initialSelectedStationsIds = useSelector(getInitialSelectedStationIds);
    const digitalStations = useDigitalSources();
    const selectedDigitalStations = digitalStations.filter(s => initialSelectedStationsIds.includes(s.id));
    selectedDigitalStations.sort((a, b) => initialSelectedStationsIds.indexOf(a.id) - initialSelectedStationsIds.indexOf(b.id));
    const anyDigitalStations = digitalStations.filter(s => !initialSelectedStationsIds.includes(s.id));
    return [ ...selectedDigitalStations, ...anyDigitalStations, ];
}

export const useRecentStations = () => {
    const lastSelectedStationsIds = useSelector(getLastSelectedStationIds);
    const region = useSelector(getRegion);
    const allSources = useSelector(getStations);
    const { stations, stationsIds } = groupSourcesInStations(allSources);
    const recentStations = stationsIds
        .filter(stationId => lastSelectedStationsIds.includes(stationId))
        .map(stationId => {     // в приоритете источники текущего региона, далее федеральные, далее любые
            const station = stations[ stationId ];
            const currentSource = station.find(src => src.regionId === region.id);
            if (currentSource) return currentSource;
            const federalSource = station.find(src => isSourceFederal(src));
            if (federalSource) return federalSource;
            return station[ 0 ];
        })
        .filter(s => s !== null);
    recentStations.sort((a, b) => lastSelectedStationsIds.indexOf(a.id) - lastSelectedStationsIds.indexOf(b.id));
    return recentStations;
}

export const useFrequencyStations = () => {
    const region = useSelector(getRegion);
    const fmSources = useFmSources();
    const { stations, stationsIds } = groupSourcesInStations(fmSources);
    const freqStations = stationsIds
        .map(stationId => {     // выбираются только источники текущего региона и федеральные
            const station = stations[ stationId ];
            const currentSource = station.find(src => src.regionId === region.id);
            if (currentSource && currentSource.frequency) return currentSource;
            return null;
        })
        .filter(s => s !== null);
    freqStations.sort((a, b) => a.frequency - b.frequency);
    return freqStations;
}

export const useFavoriteStations = () => {
    const favoriteStations = useSelector(getFavoriteStations);
    const favoriteStationsIds = useSelector(getFavoriteStationIds);
    favoriteStations.sort((a, b) => favoriteStationsIds.indexOf(a.id) - favoriteStationsIds.indexOf(b.id));
    return favoriteStations;
}

export const useSearchStations = (searchString) => {
    if (!searchString) return [];

    const stations = useSelector(getStations);
    
    const regex = new RegExp(escapeRegExp(searchString.trim()), 'i');

    const searchedSources = stations.filter(station => {
        return (
                regex.test(station.name)
            || station.frequency && regex.test(station.frequency.toString())
            || station.tags && station.tags.find(tag => regex.test('#' + tag))
        );
    });

    return searchedSources;
}