import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { ActionTypes } from "../../constants";
import Like from "../../images/like.svg";
import { getFavoriteStationIds, getRegion, getSelectedStationId } from "../../reducers";
import styled, { ThemeContext } from "styled-components";
import { IconWrapper } from "./StyledPrimitives";
import {
    StyledLink,
    ColoredStationLogo,
    DoubleStationLogo,
    MonochromeStationLogo,
    StationLogoWrapper, StationName,
    Frequency, LikeWrapper, selectedStationStyles, disabledStationStyles
} from "./StationCardPrimitives";
import { oneLineMixin } from "../Player/primitives";
import { selectStation } from "../../actions";

const Station = styled.div`
    display: flex;
    align-items: center;
    height: 84px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    ${p => p.$enabled ? (p.selected ? selectedStationStyles : '') : disabledStationStyles};
    
    ${StationLogoWrapper} {
        width: 64px;
        height: 64px;
        margin: 0 16px 0 8px;
        flex: 0 0 auto;
    }
    
    ${DoubleStationLogo} {
        width: 40px;
        height: 40px;
    }
    
    ${StationName} {
        font-size: 18px;
        line-height: 24px;
        ${oneLineMixin};
        text-align: initial;
        text-transform: uppercase;
    }
    
    ${Frequency} {
        font-size: 14px;
        line-height: 16px;
    }
    
    ${LikeWrapper} {
        position: initial;
        margin: 0 16px;
        flex: 0 0 auto;
    }
`;

const StationText = styled.div`
    min-width: 0;
    flex: 1 1 auto;
`;

const StationCard = ({ station, innerRef = null, className = '', suf }) => {
    const dispatch = useDispatch();
    const region = useSelector(getRegion);
    const location = useLocation();
    const route = location.pathname.split('/')[ 1 ];
    let suffix = '';
    if (suf) {
        suffix = '/' + suf;
    }
    else {
        if ([ 'digital', 'favorite', 'recent', 'frequency' ].indexOf(route) !== -1) suffix = '/' + route;
        if (location.pathname.split('/')[ 3 ]) suffix = '/' + location.pathname.split('/')[ 3 ];
    }
    
    const favoriteStationIds = useSelector(getFavoriteStationIds);
    const stationId = useSelector(getSelectedStationId);
    const theme = React.useContext(ThemeContext);

    const onSelect = () => {
        dispatch(selectStation(station));
    }

    const isLiked = favoriteStationIds.includes(station.id);

    return (
        <Station
            className={className}
            selected={stationId === station.id}
            $enabled={ true }
            ref={innerRef}
            onClick={ onSelect }
        >
            <StationLogoWrapper>
                <IconWrapper />
                <StyledLink
                    to={ "/station/" + station.path + suffix }
                >
                    <DoubleStationLogo>
                        <MonochromeStationLogo
                            url={theme.dark ? station.logoWhite : station.logoBlack}
                            type={theme.dark ? "white" : "black"}
                            alt={ theme.dark ? station.logoWhiteAlt : station.logoBlackAlt }
                        />
                        <ColoredStationLogo
                            url={station.logoColored}
                            type="colored"
                            alt={ station.logoColoredAlt }
                        />
                    </DoubleStationLogo>
                </StyledLink>
            </StationLogoWrapper>
            <StationText>
                <StyledLink
                    to={ "/station/" + station.path + suffix }
                >
                    <StationName>{station.name}</StationName>
                    {
                        station.regionId === region.id
                        ?
                        station.frequency
                            ? <Frequency>{station.frequency} FM</Frequency>
                            : null
                        :
                        <Frequency>{ station.defaultRegionName }</Frequency>
                    }
                </StyledLink>
            </StationText>
            <LikeWrapper
                onClick={e => {
                    e.stopPropagation();
                    dispatch({ type: ActionTypes.TOGGLE_FAVORITE, payload: station.id })
                }}
                liked={ isLiked }
            >
                <Like />
            </LikeWrapper>
        </Station>
    );
};

export default React.memo(StationCard);