import React from 'react';
import styled, { css } from "styled-components";
import { IconWrapper } from "../../reused/StyledPrimitives";
import { unitLength } from "../Frequency/css";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedStationId } from "../../../reducers";
import { ActionTypes } from "../../../constants";
import StationLogo from "../../reused/StationLogo";
import { selectStation } from "../../../actions";

const StationCircle = css`
    cursor: pointer;

    &:before {
        opacity: 1;
        flex: 0 0 auto;
        content: "";
        width: ${p => p.isSelected ? 10 : 5}px;
        height: ${p => p.isSelected ? 10 : 5}px;
        border:  1px solid ${p => p.isSelected ? p.theme.activeColor : 'rgba(128,128,128,0.3)'};
        border-radius: 1000px;
        background: ${p => p.isSelected ? p.theme.activeColor : p.theme.mainBackground};
        position: absolute;
        top: 50%; // для IE
        left: 50%;  // для IE
        transform: translateX(-50%) translateY(-50%);  // для IE
    }
    
    background-color: ${p => p.isSelected ? p.theme.activeColor : 'rgba(128,128,128,1)'};
    &:before {
        border-color: ${p => p.isSelected ? p.theme.activeColor : 'rgba(128,128,128,1)'};
    }  
`;

const SimpleVerticalLine = styled.div`
    height: ${p => p.long ? 3 * unitLength : p.isSelected ? unitLength * 1.8 : unitLength * 1.2}px;
    width: 1px;
    background: ${p => p.isSelected ? p.theme.activeColor : 'rgba(128,128,128,0.3)'};
    flex: 0 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    
    ${p => p.withStation ? StationCircle : ''};
`;

const selectedStationStyles = css`
    ${IconWrapper} {
        background: ${p => p.theme.dark ? 'rgba(255,255,255,0.8)' : 'rgba(166,171,180,0.2)'};
        transform: scale(1.05);
    }
`;

const StationPreview = styled.div`
    display: flex;
    z-index: 1;
    position: absolute;
    ${p => p.below ? 'top' : 'bottom'}: 120%;
    left: 50%;  // для IE
    transform: translateX(-50%);  // для IE
    flex-direction: column;
    align-items: center;
    width: 10em;
    flex: 0 0 auto;
    cursor: pointer;
    
    ${IconWrapper} {
        width: 5em;
        height: 5em;
        background: ${p => p.theme.getColor(0.2)};
        
        img {
            width: 70%;
            height: 70%;
        }
        
        transition: transform 1s, background-color 1s;
    }
    
    & > span {
       margin-top: 0.5em;
       text-align: center;
       opacity: 0.8;
       display: block;
       max-width: 100%; // для IE
    }
`;

const selectedTextStyles = css`
    color: ${p => p.theme.activeColor};
    opacity: 1;
    display: block;
`;

const Frequency = styled.div`
    font-size: 0.8em;
    opacity: 0.8;
    margin-top: 0.2em;
    position: absolute;
    ${p => p.below ? 'bottom' : 'top'}: ${p => p.integer ? '110%' : '90%'};
    left: 50%; // для IE
    transform: translateX(-50%); // для IE
        
    ${p => p.isSelected ? selectedTextStyles : ''}; 
`;

const Root = styled.div`
    font-size: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5em;
    margin-left: ${unitLength - 1}px;
    
    :hover {
        ${selectedStationStyles};
    }
    
    ${p => p.isSelected ? selectedStationStyles : ''};
`;

const VerticalLine = ({ station, frequency, below }) => {
    const integer = Math.abs(frequency - Math.round(frequency)) < 0.001;
    const selectedStationId = useSelector(getSelectedStationId);
    const dispatch = useDispatch();
    const isSelected = station && selectedStationId === station.id;

    return (
        <Root
            isSelected={isSelected}
            onClick={station ? () => dispatch(selectStation(station)) : null}
        >
            <SimpleVerticalLine
                long={integer}
                withStation={!!station}
                isSelected={isSelected}
            />
            {station ? <StationPreview below={below}>
                <IconWrapper><StationLogo url={station.logoColored} type="colored" /></IconWrapper>
                <span>{station.name}</span>
            </StationPreview> : null}
            {station ? <Frequency
                integer={integer}
                isSelected={isSelected}
                below={below}
            >
                {frequency}
            </Frequency> : null}
        </Root>
    );
};

export default React.memo(VerticalLine);