import React, { useEffect } from 'react';
import styled from 'styled-components';
import AllStations from "./pages/All";
import DigitalStations from "./pages/Digital";
import FavoriteStations from "./pages/Favorite";
import LastSelectedStations from "./pages/Recent";
import { useLocation, Switch, Route, Redirect } from "react-router-dom";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import HowToListen from "./pages/HowToListen";
import WriteToUs from "./pages/WriteToUs";
import Options from "./pages/Options";
import Frequency from "./pages/Frequency/Frequency";
import Frequency2 from "./pages/Frequency2/Frequency";
import DesktopMenu from "./Menu/DesktopMenu";
import { useSelector, useDispatch } from "react-redux";
import { getIsSearch, getHasStationsWithFrequency, getStations, getPages, getStyles } from "../reducers";
import SearchResults from "./Search/SearchResults";
import NotFoundPage from './pages/404';
import { Media, tabletMinWidth } from "./Media"
import { ActionTypes } from "../constants";
import { Helmet } from 'react-helmet-async';
import { getPageMetadata } from '../utils';
import config from '../../config';
import Submenu from './Menu/Submenu';

const Root = styled.div`
    font-size: 1rem;
    background: ${p => p.theme.mainBackground};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        font-size: 0.5rem;
    }
`;

const PureContent = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

const Content = styled(PureContent)`
    padding-left: 4em;
    padding-right: 1em;
`;

const PageH1 = styled.div`
    width: 0;
    height: 0;
    position: fixed;
    font-size: 24px;
    overflow: hidden;
`;

const Page = ({ children, pure = false }) => {
    const location = useLocation();
    const stations = useSelector(getStations);
    const pages = useSelector(getPages);
    const styles = useSelector(getStyles);
    
    const { title, description, keywords } = getPageMetadata(location.pathname, stations, pages, styles);

    const Wrapper = !pure ? Content : PureContent;
    return (
        <Wrapper>
            <Helmet>
                <title>{ title }</title>
                <meta name="description" content={ description } />
                <meta name="keywords" content={ keywords } />
            </Helmet>
            <PageH1><h1>{ title !== 'Radio Player' ? title.replace(' - ' + config.siteTitle, '') : title }</h1></PageH1>
            { children }
        </Wrapper>
    );
}

export default () => {
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        dispatch({ type: ActionTypes.CHANGE_LOCATION, payload: location.pathname, });
    }, [ location, ]);

    const isSearch = useSelector(getIsSearch);
    const withFrequency = useSelector(getHasStationsWithFrequency);

    return (
        <Root role="main">
            <Media at="desktop">
                <DesktopMenu />
            </Media>

            <Submenu />

            {
                isSearch
                ?
                <SearchResults />
                :
                <Switch>
                    <Route exact path='/'>
                        <Page><AllStations /></Page>
                    </Route>
                    <Route exact path='/digital'>
                        <Page><DigitalStations /></Page>
                    </Route>
                    <Route exact path='/favorite'>
                        <Page><FavoriteStations /></Page>
                    </Route>
                    <Route exact path='/recent'>
                        <Page><LastSelectedStations /></Page>
                    </Route>
                    <Route exact path='/frequency'>
                        {withFrequency ? <Page pure={ true }><Frequency /></Page> : <Redirect to="/" />}
                    </Route>
                    <Route exact path='/frequency2'>
                       <Page pure={ true }><Frequency2 /></Page>
                    </Route>
                    <Route path='/info/about'>
                        <Page><About /></Page>
                    </Route>
                    <Route path='/info/contacts'>
                        <Page><Contacts /></Page>
                    </Route>
                    <Route path='/info/how-to-listen'>
                        <Page><HowToListen /></Page>
                    </Route>
                    <Route path='/info/write-to-us'>
                        <Page><WriteToUs /></Page>
                    </Route>
                    <Route path='/options'>
                        <Page><Options /></Page>
                    </Route>
                    
                    <Route
                        path='/station/:stationName'
                        render={ props => {
                            let Target = AllStations;
                            const suffix = location.pathname.split('/')[ 3 ];
                            if (suffix) {
                                if (suffix === 'frequency') {
                                    return withFrequency ? <Page pure={ true }><Frequency /></Page> : <Redirect to="/" />;
                                }
                                if (suffix === 'digital') Target = DigitalStations;
                                if (suffix === 'recent') Target = LastSelectedStations;
                                if (suffix === 'favorite') Target = FavoriteStations;
                                if (suffix === 'search') Target = SearchResults;
                            }
                            return (
                                <Page>
                                    <Target />
                                </Page>
                            );
                        }}
                    />
                    
                    <Route
                        path='/style/:string'
                        render={ props => (
                            <Page>
                                <SearchResults string={ props.match.params.string } />
                            </Page>
                        ) }
                    />

                    <Route path='/'>
                        <Page><NotFoundPage /></Page>
                    </Route>
                </Switch>
            }

        </Root>
    )
}