import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { tabletMinWidth, desktopMinWidth } from "../Media";

export const MenuItem = styled(NavLink)`
    font-size: 18px;
    text-transform: uppercase;
    opacity: 0.3;
    margin-right: 32px;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    transition: all 0.5s;
    
    &.active, :hover {
        font-size: 32px;
        opacity: 0.8;
    }
    
    @media (min-width: ${desktopMinWidth}px) and (max-width: ${desktopMinWidth + 120}px) {
        font-size: 16px;
        margin-right: 16px;
        
        &.active, :hover {
            font-size: 28px;
        }
    }

    @media (max-width: ${tabletMinWidth - 1}px) {
        font-size: 16px;
        margin-right: 16px;
        
        &.active, :hover {
            font-size: 20px;
        }
    }
`;

export const IconMenuItem = styled(NavLink)`
    opacity: 0.5;
    margin-right: 32px;
    cursor: pointer;
    color: inherit;

    svg {
        width: 28px;
        height: 28px;
        transition: all 0.5s;
    }

    &.active, :hover {
        opacity: 0.8;

        svg {
            width: 42px;
            height: 42px;
        }
    }

    @media (min-width: ${desktopMinWidth}px) and (max-width: ${desktopMinWidth + 120}px) {
        margin-right: 16px;
        svg {
            width: 24px;
            height: 24px;
        }
        &.active, :hover {
            svg {
                width: 38px;
                height: 38px;
            }
        }
    }

    @media (max-width: ${tabletMinWidth - 1}px) {
        margin-right: 16px;
        svg {
            width: 22px;
            height: 22px;
        }
        &.active, :hover {
            svg {
                width: 32px;
                height: 32px;
            }
        }
    }
`;

export const MainMenu = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    
    @media (max-width: ${desktopMinWidth - 1}px) {
        margin-bottom: 2em;
    }

    &.hidden {
        display: none;
    }
`;
