import React from 'react';
import styled from "styled-components";
import MainLogo from "../reused/MainLogo";
import CrossIcon from '../../images/cross.svg';
import { NavLink } from "react-router-dom";
import Vk from "../../images/vk.svg";
import Ok from "../../images/ok.svg";
import Fb from "../../images/fb.svg";
import Youtube from "../../images/youtube.svg";
import Instagram from "../../images/instagram.svg";
import { SocialButton } from "../reused/StyledPrimitives";

const MobileInfoMenu = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;
    z-index: 9999;
    background: ${p => p.theme.mainBackground};
    flex-direction: column;
    padding: 4em;
    box-sizing: border-box;
    
    display: ${p => p.opened ? 'flex' : 'none'};
`;

const UpperLogo = styled.div`
    display: flex;
    margin-bottom: 32px;
    align-items: center;
    height: 32px;
`;

const InfoMenuEntry = styled(NavLink)`
    font-size: 18px;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    display: block;
    height: 67px;
    line-height: 67px;
    opacity: 0.8;
    text-transform: uppercase;
    
    :after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background: ${p => p.theme.color};
        opacity: 0.1;
    }
`;

const BottomBlock = styled.div`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    max-height: 120px;
`;

const CloseButton = styled(CrossIcon)`
    width: 24px;
    height: 20px;
    opacity: 0.8;
    cursor: pointer;
    flex: 0 0 auto;
    margin-right: 3em;
`;

const SocialButtonsWrapper = styled.div`
    display: flex;
    && > * {
        margin: 2em;
    }
`;

const Developer = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    opacity: 0.5;
    letter-spacing: 0.02em;
    margin: auto 0;
`;

export default ({ opened = false, onClose = () => {} }) => {
    return (
        <MobileInfoMenu opened={opened}>
            <UpperLogo>
                <CloseButton onClick={onClose} />
                <MainLogo />
            </UpperLogo>

            <InfoMenuEntry to="/info/about" onClick={onClose}>О проекте</InfoMenuEntry>
            <InfoMenuEntry to="/info/contacts" onClick={onClose}>Контакты</InfoMenuEntry>
            <InfoMenuEntry to="/info/how-to-listen" onClick={onClose}>Как нас слушать</InfoMenuEntry>
            <InfoMenuEntry to="/info/write-to-us" onClick={onClose}>Написать нам</InfoMenuEntry>

            <BottomBlock>
                <SocialButtonsWrapper>
                    <SocialButton><Vk /></SocialButton>
                    <SocialButton><Ok /></SocialButton>
                    <SocialButton><Fb /></SocialButton>
                    <SocialButton><Youtube /></SocialButton>
                    <SocialButton><Instagram /></SocialButton>
                </SocialButtonsWrapper>
                <Developer>Разработчик сайта Микро-ИТ</Developer>
            </BottomBlock>
        </MobileInfoMenu>
    );
}