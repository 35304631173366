import React, { useEffect } from 'react';
import Location from "../../images/location_small.svg";
import Settings from "../../images/settings.svg";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIsSearch, getLocationSelectOpened, getRegionName } from "../../reducers";
import { useLocation } from "react-router-dom";
import { ActionTypes } from "../../constants";
import Info from "./Info";
import { IconControl, MenuIconWrapper } from "./primitives";
import { IconWrapper } from "../reused/StyledPrimitives";
import AppsLinks from './AppsLinks';
import { tabletMinWidth } from "../Media";

const ActiveLink = css`
    &.active {
        svg {
            color: ${p => p.theme.activeColor};
            opacity: 1;
        }
    }
`;

const IconLink = styled(({ isSearch, ...rest }) => <NavLink {...rest} />)`
    color: inherit;
    text-decoration: none;

    ${p => p.isSearch ? '' : ActiveLink};
`;

const Root = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
`;

const RegionWrapper = styled(IconWrapper)`
    height: 4rem;
    // margin-right: 1em;
    background: transparent;
    cursor: pointer;
`;

const RegionName = styled.span`
    font-size: 1.75rem;
    font-family: Roboto, sans-serif;
    opacity: .5;
    padding: 0 1em 0 .25em;

    @media (max-width: ${tabletMinWidth - 1}px) {
        display: none;
    }
`;

export default () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isSearch = useSelector(getIsSearch);
    const regionName = useSelector(getRegionName);
    
    const selectOpened = useSelector(getLocationSelectOpened);
    const handleRegionClick = () => {
        if (!selectOpened) {
            dispatch({ type: ActionTypes.OPEN_LOCATION_SELECT, });
        }
        else {
            dispatch({ type: ActionTypes.CLOSE_LOCATION_SELECT, });
        }
    }
    useEffect(() => {
        if (selectOpened) dispatch({ type: ActionTypes.CLOSE_LOCATION_SELECT, });
    }, [ location ]);

    return (
        <Root>
            <RegionWrapper onClick={ handleRegionClick }>
                <IconControl
                    as={ Location }
                    role="button"
                    aria-label="Регион"
                />
                <RegionName>
                    { regionName }
                </RegionName>
            </RegionWrapper>
            
            <AppsLinks />

            <Info role="button" aria-label="Дополнительное меню" />
            
            <MenuIconWrapper>
                <IconLink
                    to='/options'
                    onClick={() => dispatch({ type: ActionTypes.END_SEARCH })}
                    isSearch={isSearch}
                >
                    <IconControl
                        as={Settings}
                        role="button"
                        aria-label="Настройки"
                    />
                </IconLink>
            </MenuIconWrapper>
        </Root>
    );
};