import React from 'react';
import FrequencyTrack from "./FrequencyTrack";
import { useDispatch, useSelector } from "react-redux";
import { getHasStationsWithFrequency } from "../../../reducers";

export default () => {
    const withFrequency = useSelector(getHasStationsWithFrequency);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!withFrequency) {
            dispatch({ type: 'mimic_moscow' });
        }
    });

    if (!withFrequency) return null;

    return (
        <FrequencyTrack />
    )
}