import React, { useRef } from 'react';
import styled from 'styled-components';
import StationCard from "../../reused/StationCard";
import { useSelector } from "react-redux";
import { getSelectedStationId } from "../../../reducers";

const Root = styled.div`
    font-size: 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 3em 0 6em 0;
    position: relative;
`;

const List = styled.div`
    display: flex;
    position: relative;
    transition: left 1s;
`;

const StyledStationCard = styled(StationCard)`
    flex: 0 0 auto;
    transition: transform 0.5s;
    ${p => {
    if (p.distanceFromSelected) {
        const opacity = 1 / (p.distanceFromSelected ** 1.5);
        const modernCssProp = `opacity: ${p.$enabled ? opacity : `min(0.3, ${opacity})`};`;
        const ieCssProp = `opacity: ${opacity};`;
        return ieCssProp + modernCssProp;
    } else {
        return 'transform: scale(1.35)';
    }
}};
    
    :hover {
        opacity: 1;
    }
`;

export default ({ stations }) => {
    const selectedStationId = useSelector(getSelectedStationId);
    const selectedStationRef = useRef(null);
    const listRef = useRef(null);
    const rootRef = useRef(null);

    React.useEffect(() => {
        if (!selectedStationRef.current) return;

        const stationRect = selectedStationRef.current.getBoundingClientRect();
        const listRect = listRef.current.getBoundingClientRect();
        const rootRect = rootRef.current.getBoundingClientRect();

        const listLeftShift = rootRect.width / 2 + rootRect.left - stationRect.left - stationRect.width / 2;
        listRef.current.style.left = listRect.left + listLeftShift - rootRect.left + 'px';
    }, [selectedStationId]);

    const selectedIndex = stations.findIndex(station => station.id === selectedStationId);

    return (
        <Root ref={rootRef}>

            <List ref={listRef}>
                {stations.map((station, i) => {
                    return (
                        <StyledStationCard
                            innerRef={selectedStationId === station.id ? selectedStationRef : null}
                            distanceFromSelected={Math.abs(selectedIndex - i)}
                            key={station.id}
                            $enabled={station.enabled}
                            station={station}
                            styles={{ hideText: selectedStationId !== station.id }}
                        />
                    );
                })}
            </List>
        </Root>
    )
};
