import styled from "styled-components";
import { tabletMinWidth, desktopMinWidth } from "../Media";
import { loadingAnimation } from "../reused/StyledPrimitives";
import RefreshIcon from '../../images/sync.svg';

export const Refresh = styled(RefreshIcon)`
    height: 1.5em;
    width: 1.5em;
    min-width: 1.5em;
    margin-right: 1em;
    cursor: pointer;
    ${p => p.$loading ? loadingAnimation : ''};
`;

export const Root = styled.div`
    position: absolute;
    z-index: 8888;
    width: 100%;
`;

export const MainWrapper = styled.div`
    border-radius: 5px;
    border: none;
    height: 50vh;
    min-height: 240px;
    overflow: hidden;
    font-size: 2rem;
    font-family: Roboto, sans-serif;
    background-color: ${ p => p.theme.regionsPopup };
    padding: 1em;
    margin: .5em 1em 0 2em;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity .5s;

    @media (max-width: ${tabletMinWidth - 1}px) {
        margin: .5em;
    }

    &.opened {
        opacity: 1;
        transition: opacity .5s;
    }
`;

export const TopWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const InputWrapper = styled.div`
    margin: .5em 1em;
    background-color: ${ p => p.theme.mainBackground };  
    padding: .5em 1em;
    border-radius: 5px;
    flex: 1 1 auto;
    display: flex;
    color: ${ p => p.theme.color };

    @media (max-width: ${tabletMinWidth - 1}px) {
        padding: .25em;
    }
`;

export const Input = styled.input`
    flex: 1 1 auto;
    background-color: transparent;
    border: none;
    outline: none;
    color: ${ p => p.theme.color };
    font-size: 0.875em;
    opacity: 0.8;
    caret-color: ${ p => p.theme.activeColor };
    min-width: 0;
    
    ::placeholder {
        color: #7f7f7f;
    }
`;

export const RegionsListWrapper = styled.div`
    height: 41vh;
`;

export const RegionsList = styled.ul`
    padding-left: 1em;
`;

export const RegionsListItem = styled.li`
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: .25em 0;
    color: ${ p => p.theme.color };
    opacity: .5;
    
    &.active, :hover {
        color: ${ p => p.theme.activeColor };
        opacity: .8;
        cursor: pointer;
    }
    &.active {
        pointer-events: none;
    }

    width: 25%;
    @media (min-width: ${tabletMinWidth}px) and (max-width: ${desktopMinWidth - 1}px) {
        width: 50%;
    }
    @media (max-width: ${tabletMinWidth - 1}px) {
        width: 100%;
    }
`;
