import React from 'react';
import AppAppStore from "../../images/logo-app-store.svg";
import AppPlayMarket from "../../images/logo-google-play.svg";
import AppAppGallery from "../../images/logo-app-gallery.svg";
import AppRuStore from "../../images/logo-ru-store.svg";
import styled from "styled-components";
import { IconControl } from "./primitives";
import { tabletMinWidth } from "../Media";
import { useAppsLinks } from '../hooks';

const AppsLinksContainer = styled.div`
    display: flex;

    @media (max-width: ${tabletMinWidth - 1}px) {
        height: 48px;
        justify-content: center;
        align-items: center;
        background: ${p => p.theme.mainBackground};
    }
`;

const AppLink = styled.span`
    width: 42px;
    height: 42px;
    overflow: hidden;
    cursor: pointer;
    
    @media (max-width: ${ tabletMinWidth - 1 }px) {
        height: 26px;
        width: 26px;
    }

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const AppIconControl = styled.svg`
    opacity: 0.8;  
    flex: 0 0 auto;  
    cursor: pointer;
    
    :hover {
        opacity: 1;
    }
`;

export default () => {
    const { onClickAndrBtn, onClickIosBtn, onClickAppGalleryBtn, onClickRuStoreBtn } = useAppsLinks();

    return (
        <AppsLinksContainer>
            <AppLink onClick={ onClickAndrBtn }>
                <AppIconControl
                    role="button"
                    aria-label="Перейти к приложению в Google Play Market"
                    as={ AppPlayMarket }
                />
            </AppLink>

            <AppLink onClick={ onClickIosBtn }>
                <AppIconControl
                    role="button"
                    aria-label="Перейти к приложению в Apple App Store"
                    as={ AppAppStore }
                />
            </AppLink>

            <AppLink onClick={ onClickRuStoreBtn }>
                <AppIconControl
                    role="button"
                    aria-label="Перейти к приложению в RuStore"
                    as={ AppRuStore }
                />
            </AppLink>

            <AppLink onClick={ onClickAppGalleryBtn }>
                <AppIconControl
                    role="button"
                    aria-label="Перейти к приложению в Huawei App Gallery"
                    as={ AppAppGallery }
                />
            </AppLink>
        </AppsLinksContainer>
    );
}