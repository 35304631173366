import { constant } from './constants';

export const ActionTypes = constant({
    INIT: null,
    OPEN_LOCATION_SELECT: null,
    CLOSE_LOCATION_SELECT: null,
    CHANGE_LOCATION: null,
    SELECT_STATION: null,
    TOGGLE_PLAYING: null,
    METADATA_UPDATED: null,
    SET_VOLUME: null,
    TOGGLE_MUTE: null,
    TOGGLE_FAVORITE: null,
    SET_DATA_FROM_LOCAL_STORAGE: null,
    TOGGLE_THEME: null,
    START_SEARCH: null,
    END_SEARCH: null,
    SET_SEARCH_STRING: null,
    SET_STATIONS: null,
    SET_INITIAL_SELECTED_STATIONS_IDS: null,
    SET_PAGES: null,
    SET_STYLES: null,
    TOGGLE_REGION_MODE: null,
    SET_REGIONS: null,
    SET_REGION: null,
    CHANGE_REGION_MANUALLY: null,
    SET_WAITING: null,
    UPDATE_REGION: null,
    REGION_UPDATED: null,
    SOCIAL_CLICK: null,
    APP_CLICK: null,
    FEEDBACK_SEND: null,
    HOVER_STATION: null,
});