import React from 'react';
import styled from "styled-components";
import { oneLineMixin } from './primitives';
import Ticker from "../reused/Ticker";

const Artist = styled.div`
    font-size: 18px;
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 4px;
    ${oneLineMixin};
`;

const CurrentSong = styled(Ticker)`
    font-size: 44px;
    text-transform: uppercase;
    opacity: 0.8;
`;

const NextSong = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 16px;
    opacity: 0.5;
    margin-top: 2em;
    ${oneLineMixin};
`;

export default ({ metadata }) => {
    return (
        <div>
            <Artist title={metadata.artist || ''}>{metadata.artist || '\u00A0'}</Artist>
            <CurrentSong text={metadata.title || '\u00A0'} />
            <NextSong>&nbsp;</NextSong>
        </div>
    );
};