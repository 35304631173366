/**
 * Методы для сохранения и получения пользовательских данных в LocalStorage и cookies
 */

import { ActionTypes, Constants } from "../constants";
import { delay, put, select } from "redux-saga/effects";
import Cookie from "js-cookie";
import { getFavoriteStationIds, getLastSelectedStationIds, getVolume, getRegion } from "../reducers";
import config from "../../config";

export function* getDataFromLocalStorage() {
    try {
        let favoriteStationIds = localStorage.getItem(Constants.FAVORITE_STATION_IDS_STORAGE_KEY);
        let lastSelectedStationIds = localStorage.getItem(Constants.LAST_SELECTED_STATION_IDS_STORAGE_KEY);
        let manuallySelectedRegion = localStorage.getItem(Constants.MANUALLY_SELECTED_REGION_STORAGE_KEY);
        favoriteStationIds = favoriteStationIds ? JSON.parse(favoriteStationIds) : [];
        lastSelectedStationIds = lastSelectedStationIds ? JSON.parse(lastSelectedStationIds) : [];
        manuallySelectedRegion = manuallySelectedRegion ? JSON.parse(manuallySelectedRegion) : null;
        const theme = Cookie.get('theme'); // вообще это уже должно быть учтено на сервере
        yield put({
            type: ActionTypes.SET_DATA_FROM_LOCAL_STORAGE,
            favoriteStationIds,
            lastSelectedStationIds,
            region: manuallySelectedRegion,
            ...(theme && { theme: theme }),
        });
        Cookie.set('favorite_stations_ids', favoriteStationIds.toString());
        Cookie.set('last_selected_stations_ids', lastSelectedStationIds.toString());
    } catch (e) {
        console.warn('Error on attempt to read favorite and last selected station ids from the local storage!');
    }
}

export function* saveFavoriteStationIds(action) {
    const state = yield select();
    const favoriteStationIds = getFavoriteStationIds(state);
    try {
        localStorage.setItem(
            Constants.FAVORITE_STATION_IDS_STORAGE_KEY,
            JSON.stringify(favoriteStationIds)
        );
    } catch (e) {
        console.warn('Error on attempt to save favorite station ids to the local storage!');
    }
    Cookie.set('favorite_stations_ids', favoriteStationIds.toString());
    try {
        yield fetch(config.apiHost + '/api/web/favorite', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                stationId: action.payload,
                liked: favoriteStationIds.includes(action.payload),
            })
        });
    } catch (e) {
        console.error(e);
    }
}

export function* saveLastSelectedStationIds() {
    const state = yield select();
    const lastSelectedStationIds = getLastSelectedStationIds(state);
    try {
        localStorage.setItem(
            Constants.LAST_SELECTED_STATION_IDS_STORAGE_KEY,
            JSON.stringify(lastSelectedStationIds)
        );
        Cookie.set('last_selected_stations_ids', lastSelectedStationIds.toString());
    } catch (e) {
        console.error(e);
        console.warn('Error on attempt to save last selected station ids to the local storage!');
    }
}

export function* saveManuallySelectedRegion(action) {
    const region = action.payload;
    try {
        localStorage.setItem(
            Constants.MANUALLY_SELECTED_REGION_STORAGE_KEY,
            JSON.stringify(region)
        );
        Cookie.set('manually_selected_region_id', region.id);
    } catch (e) {
        console.error(e);
        console.warn('Error on attempt to save manually selected region to the local storage!');
    }
}

export function* readOptions() {
    try {
        let options = localStorage.getItem(Constants.OPTIONS_STORAGE_KEY);
        options = options ? JSON.parse(options) : null;
        if (!options || options.volume == null) {
            return;
        }

        yield put({
            type: ActionTypes.SET_VOLUME,
            payload: options.volume,
        });
    } catch (e) {
        console.warn('Error on attempt to read options from the local storage!');
    }
}

export function* saveOptions() {
    yield delay(1000); // так как при изменении звука много событий, оптимизация
    const state = yield select();
    const volume = getVolume(state);
    const options = { volume };

    try {
        localStorage.setItem(Constants.OPTIONS_STORAGE_KEY, JSON.stringify(options));
    } catch (e) {
        console.warn('Error on attempt to save options to the local storage!');
    }
}