export function constant(obj) {
    for (let key in obj) {
        if (obj[key] === null) {
            obj[key] = key;
        }
    }
    return Object.freeze(obj);
}

const Constants = constant({
    FAVORITE_STATION_IDS_STORAGE_KEY: 'favorite_stations',
    LAST_SELECTED_STATION_IDS_STORAGE_KEY: 'recent_stations',
    OPTIONS_STORAGE_KEY: 'options',
    MANUALLY_SELECTED_REGION_STORAGE_KEY: 'manually_selected_region',
});

export default Constants;