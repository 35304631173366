/**
 * Логика получения региона и сопутствующих данных
 */

import config from "../../config";
import { put, select } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import Toaster from "../components/Toaster";
import sendStatEvent from "./stats";
import Cookie from "js-cookie";
import { translitString } from '../utils';
import { getFavoriteStationIds, getLastSelectedStationIds } from "../reducers";

export function* updateStationsByRegion(region, manuallyChanged = false) {
    const state = yield select();
    const lastSelectedStationsIds = getLastSelectedStationIds(state);
    const favoriteStationsIds = getFavoriteStationIds(state);
    let url = config.apiHost + '/api/web/site/stations?region=' + region.id;
    if (favoriteStationsIds.length) url += `&favoriteStationsIds=${favoriteStationsIds.toString()}`;
    const stations = yield fetch(url).then(res => res.json());
    if (stations && stations.length) {
        yield put({ type: ActionTypes.SET_INITIAL_SELECTED_STATIONS_IDS, payload: lastSelectedStationsIds, });
        yield put({ type: ActionTypes.SET_REGION, payload: region, });
        yield put({ type: ActionTypes.SET_STATIONS, payload: stations, });
        Toaster.show(
            (region.id === 1 && !manuallyChanged)
            ? `Не удалось определить регион. Используем федеральное вещание.`
            : `Регион изменен: ${region.name}`
        );
    } else {
        console.log('Нет станций для региона ', region.name);
    }
}

export function* changeRegionManually(action) {
    yield updateStationsByRegion(action.payload, true);
}

export function* updateRegionData(manual = true) {
    yield* sendStatEvent('location_request');
    try {
        const position = yield new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        const url = `${config.apiHost}/api/web/region?latitude=${latitude}&longitude=${longitude}`;
        const region = yield fetch(url).then(res => res.json());
        
        if (region) Cookie.set('region', region.name);
        yield* sendStatEvent('location_accept');
        Cookie.set('location_enabled', true);

        if (region || manual) {
            yield* updateStationsByRegion(region || { id: 1, name: "Россия" });
        }
    } catch (e) {
        // console.error(e);
        Cookie.set('region', translitString('Россия'));
        yield* sendStatEvent('location_decline');
        Cookie.set('location_enabled', 'false');
        if (manual) {
            let message = `Ошибка при обновлении региона. `;
            if ((e instanceof GeolocationPositionError) && e.code === 1) {
                message += "Отказано в доступе к данным геолокации.";
            }
            Toaster.show(message);
        }
    } finally {
        yield put({ type: ActionTypes.REGION_UPDATED });
    }
}