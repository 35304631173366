import React from 'react';
import styled from 'styled-components';
import { Header } from "../reused/StyledPrimitives";
import Scrollbar from "../reused/Scrollbar";

const Text = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 18px;
    opacity: 0.8;
    margin-bottom: 1em;
    letter-spacing: 0.02em;
    
    a {
        color: ${p => p.theme.activeColor};
    }
`;

const Description = styled.div`
    max-width: 800px;
    margin-bottom: 5em;

    p {
        font-family: Roboto, sans-serif;
        font-size: 18px;
        letter-spacing: 0.02em;
        line-height: 1.5;
        opacity: 0.9;
    }
`;

export default () => {
    return (
        <Scrollbar>
            <Header>О проекте</Header>

            <Description>
                <p>Слушайте любимые радиостанции онлайн в одном приложении, сохраняйте понравившиеся в закладки и наслаждайтесь музыкой на любой вкус. Высокое качество звука, удобный интерфейс, ничего лишнего — всё это совершенно бесплатно.</p>
                <p>В приложении представлены эфирные федеральные радиостанции и радиостанции вашего региона.</p>
                <p>Выбирайте радио из обширного каталога. Добавляйте любимые станции в «избранное».</p>
                <p>Возвращайтесь к недавно прослушанным радиостанциям в специальной вкладке.</p>
                <p>Не отвлекайтесь от своих занятий: слушайте любимую музыку в фоновом режиме.</p>
            </Description>

            <Text>Версия 1.0.0, сборка 100500</Text>
            <Text>
                Разработчик сервиса и сайта <a href="http://micro-it.ru" rel="noreferrer" target="_blank">Микро-ИТ</a>
            </Text>
            <Text>
                <a href="/policy" rel="noreferrer" target="_blank">Политика конфиденциальности</a>
            </Text>

        </Scrollbar>
    );
}