import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getIsSearch, getSearchString } from "../../reducers";
import { ActionTypes } from "../../constants";
import HintList from "./HintList";
import SearchIcon from "../../images/search.svg";
import { Root, InputWrapper, Input, StyledCloseIcon } from './SearchStyles';

export default () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const searchString = useSelector(getSearchString);
    const isSearch = useSelector(getIsSearch);
    const [ value, setValue ] = useState(null);
    const inputRef = useRef(null);

    const handleValueChange = e => {
        setValue(e.target.value);
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            dispatch({ type: ActionTypes.START_SEARCH, });
            dispatch({
                type: ActionTypes.SET_SEARCH_STRING,
                payload: e.target.value
            });
            setValue(null);
        }
    }
    useEffect(() => {
        // сброс поиска при смене страницы
        if (!isSearch) return;
        if (location.pathname.indexOf('/style/') !== -1) return;
        if (location.pathname.indexOf('/station/') === -1) {
            dispatch({ type: ActionTypes.END_SEARCH, });
        }
    }, [ location ]);

    const handleHintSelect = option => {
        if (!isSearch) dispatch({ type: ActionTypes.START_SEARCH, });
        dispatch({ type: ActionTypes.SET_SEARCH_STRING, payload: option.toString() })
        setValue(null);
    }

    const handleClear = () => {
        if (location.pathname.indexOf('/style/') !== -1) history.push('/');
        dispatch({ type: ActionTypes.SET_SEARCH_STRING, payload: '', });
        dispatch({ type: ActionTypes.END_SEARCH });
        setValue(null);
        inputRef.current.focus();
    }

    return (
        <Root>
            <InputWrapper>
                <SearchIcon />
                <Input
                    placeholder="Введите станцию, частоту, жанр..."
                    value={ value === null ? searchString : value }
                    onChange={ handleValueChange }
                    onKeyPress={ handleKeyPress }
                    ref={ inputRef }
                />
            </InputWrapper>
            {
                value
                &&
                <HintList
                    searchValue={ value }
                    onHintSelect={ handleHintSelect }
                />
            }
            {
                isSearch
                &&                
                <StyledCloseIcon onClick={ handleClear } />
            }
        </Root>
    );
};