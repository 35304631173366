import React from 'react';
import styled from "styled-components";
import Tags from "../Tags";
import { oneLineMixin } from '../primitives';
import { tabletMinWidth } from "../../Media";
import MobileStationSocialButtons from "./MobileStationSocialButtons";
import Ticker from "../../reused/Ticker";

const StationName = styled.span`
    text-transform: uppercase;
    ${oneLineMixin}; 
`;

const StationNameWrapper = styled.div`
    font-size: 18px;
    line-height: 26px;
    opacity: 0.5;
    ${oneLineMixin};
    margin-right: 1em;
    margin-bottom: 0.3em;
    display: flex;
    align-items: center;
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        font-size: 14px;
        line-height: 24px;
    }
`;

const SongName = styled(Ticker)`
    font-size: 24px;
    line-height: 28px;
    opacity: 0.8;
    margin-top: 1em;
    text-transform: uppercase;
    ${oneLineMixin};  
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        font-size: 14px;
        line-height: 16px;
        margin-top: 0.5em;
        text-transform: none;
        font-family: Roboto, sans-serif;
    }
`;

const Root = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-left: 3em;
    height: 130px;
    min-width: 0;
`;

export default ({ station, metadata }) => {
    return (
        <Root>
            <StationNameWrapper>
                <MobileStationSocialButtons station={station} />
                <StationName>
                    {station.frequency ? station.frequency + ' FM \u00A0\u00A0' : ''}{station.name}
                </StationName>
            </StationNameWrapper>
            <Tags tags={station.tags} />
            <SongName text={(metadata.artist ? metadata.artist + ' - ' : '') + (metadata.title || '\u00A0')} />
        </Root>
    )
}