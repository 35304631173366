import Vk from "../../images/vk.svg";
import Youtube from "../../images/youtube.svg";
import Fb from "../../images/fb.svg";
import Twitter from "../../images/twitter.svg";
import Tiktok from "../../images/tiktok.svg";
import Zen from "../../images/zen.svg";
import Ok from "../../images/ok.svg";
import Instagram from "../../images/instagram.svg";
import Website from "../../images/www.svg";

const typeToIcon = {
    vk: Vk,
    youtube: Youtube,
    facebook: Fb,
    twitter: Twitter,
    tiktok: Tiktok,
    zen: Zen,
    ok: Ok,
    instagram: Instagram,
    website: Website,
};

export function getSocialLinksWithIcons(socialLinks) {
    if (!socialLinks || !socialLinks.length) {
        return null;
    }
    return socialLinks.map(link => {
        const Icon = typeToIcon[link.type];
        if (!Icon) console.warn('No icon for link ', link);

        return { Icon: Icon || Website, url: link.url, type : link.type, };
    }).filter(a => a);
}