import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { getMetadata, getSelectedStation } from "../../reducers";
import Volume from "./Volume";
import StationInfo from "./StationInfo";
import MetadataText from "./MetadataText";
import DoubleCover from "./DoubleCover";
import { playerBackground } from "./primitives";
import PlayButton from "./PlayButton";
import MainLogo from "../reused/MainLogo";
import { getWebpCoverFromMetadata } from '../../utils';

const Root = styled.div`
    padding: 0 4em 4em 5em;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    ${playerBackground};
`;

const LogoWrapper = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    margin-bottom: auto;
    z-index: 0; // почему-то иначе покрывается фоном
`;

const CoverWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-bottom: 20%;
    max-width: 90%;
`;

const StyledPlayButton = styled(PlayButton)`
   left: 2em;
   margin: 0 auto;
`;

export default () => {
    const station = useSelector(getSelectedStation);
    const metadata = useSelector(getMetadata);
    const cover = getWebpCoverFromMetadata(metadata);

    const [backgroundImage, setBackgroundImage] = useState('');

    return (
        <Root backgroundImage={backgroundImage}>
            <LogoWrapper>
                <MainLogo />
                <Volume />
            </LogoWrapper>
            <CoverWrapper>
                <DoubleCover
                    cover={ cover }
                    onCoverChanged={setBackgroundImage}
                />
                <StyledPlayButton />
            </CoverWrapper>

            <MetadataText metadata={metadata} />
            <StationInfo station={station} />

        </Root>
    )
}