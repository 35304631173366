import React from 'react';
import styled from "styled-components";
import MainMenu from "./MainMenu";
import AdditionalMenu from "./AdditionalMenu";

const DesktopMenu = styled.nav`
    height: 100px;
    display: flex;
    align-items: center;
    padding-left: 4em;
    padding-right: 3em;
`;

const Wrapper = styled.div`
    display: ${p => p.isSearch ? 'none' : 'flex'};
    align-items: center;
    flex: 1 1 auto;
`;

export default () => {
    return (
        <DesktopMenu>
            <Wrapper>
                <MainMenu />
                <AdditionalMenu />
            </Wrapper>
        </DesktopMenu>
    )
};