/**
 * Функции работы с плеером (со звуком)
 */

import { eventChannel } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { getIsMuted, getIsPlaying, getSelectedStation, getVolume, getMetadata } from "../reducers";
import { ActionTypes } from "../constants";
import sendStatEvent, { sendPlayStatistics, sendStopStatistics } from "./stats";
import Toaster from "../components/Toaster";
import MelonPlayer from 'melonplayer-core';
import { saveOptions } from "./userData";
import config from "../../config";

const div = document.createElement('div');
div.style.cssText = 'position: absolute; top: -999em; left: -999em; width: 0; height: 0; opacity: 0';
document.body.appendChild(div);
let playerConfig = null;
let player = null;
let waitingTimeout = 0;
let errorTimeout = 0;
let playerEventChannelEmitter;

export const playerEventChannel = eventChannel(emitter => {
    playerEventChannelEmitter = emitter;
    return () => {};
});

function clearPlayerTimeouts() {
    clearTimeout(waitingTimeout);
    waitingTimeout = 0;
    clearTimeout(errorTimeout);
    errorTimeout = 0;
}

function* createToken() {
    let url = config.apiHost + '/api/web/site/gts';
    const token = yield fetch(url).then(res => res.json());
    return token;
}
function addTokenToUrl(sourceUrl, token) {
    const url = new URL(sourceUrl);
    url.searchParams.set('st', token.st);
    url.searchParams.set('gts', token.gts);
    return url.toString();
}
function* addTokenToSource(source) {
    const token = yield createToken();
    return typeof source === 'string'
        ? addTokenToUrl(source, token)
        : source.map(url => addTokenToUrl(url, token));
}
function* updatePlayerSourceToken() {
    const source = yield addTokenToSource(player.config.source);
    player.configure({ ...player.config, source, });
}

export function* recreatePlayer() {
    const state = yield select();
    const volume = getVolume(state);
    const isMuted = getIsMuted(state);
    const isPlaying = getIsPlaying(state);
    const station = getSelectedStation(state);

    if (!station.source || !station.source.length || !station.metadata) {
        yield put({ type: ActionTypes.METADATA_UPDATED, metadata: {} });
    }

    clearPlayerTimeouts();
    player && player.destroy();
    player = null;

    if (!station.source || !station.source.length) return;
    const source = isPlaying
        ? yield addTokenToSource(station.source)
        : station.source;

    playerConfig = {
        source,
        parent: div,
        metadata: station.metadata,
        state: {
            volume: volume,
            isMuted: isMuted,
            isPlaying: isPlaying,
        }
    };

    player = new MelonPlayer.Core(playerConfig);
    player.on(MelonPlayer.Events.METADATA_UPDATED, event => {
        playerEventChannelEmitter({ type: MelonPlayer.Events.METADATA_UPDATED, metadata: event.metadata });
    });

    player.on(MelonPlayer.Events.WAITING, () => {
        // событие WAITING может вызываться много раз, когда плеер пересоздается после ошибки
        // и пытается заново запустить поток
        if (!waitingTimeout) {
            waitingTimeout = setTimeout(() => {
                playerEventChannelEmitter({ type: ActionTypes.SET_WAITING, payload: true });
                waitingTimeout = 0;
            }, 2000);
        }
    });

    player.on(MelonPlayer.Events.PLAYING, () => {
        clearPlayerTimeouts();
        playerEventChannelEmitter({ type: ActionTypes.SET_WAITING, payload: false });
    });

    player.on(MelonPlayer.Events.ERROR, () => {
        if (!errorTimeout) {
            errorTimeout = setTimeout(() => {
                playerEventChannelEmitter({ type: MelonPlayer.Events.ERROR });
                errorTimeout = 0;
            }, 10000); // у ГМП несколько источников. Первые могут быть недоступны, но это нормально, поэтому 10 секунд
        }
    });

    void player.checkMetadataUpdate(); // загружаем метаданные, хотя если плеер играет, то они и так загрузятся
}

export function* togglePlaying() {
    const state = yield select();
    if (getIsPlaying(state)) {
        if (player) {
            yield updatePlayerSourceToken();
            player.play();
            yield* sendPlayStatistics();
        }
    } else {
        if (player) {
            player.stop();
            // отправка события завершения прослушивания текущего элемента
            const meta = getMetadata(yield select());
            yield sendStatEvent('listen', null, { meta, });
            yield* sendStopStatistics();
        }
    }
}

function _changeLinksToAbsoluteInUrls(metadata, baseUrl) {
    if (metadata.cover) {
        metadata.cover = new URL(metadata.cover, baseUrl).toString();
    }

    if (metadata.prev_tracks && metadata.prev_tracks.length) {
        metadata.prev_tracks.forEach(obj => {
            if (obj.cover) {
                obj.cover = new URL(obj.cover, baseUrl).toString();
            }
        });
    }

    return metadata;
}

export function* processPlayerEvents(event) {
    if (event.type === MelonPlayer.Events.METADATA_UPDATED) {
        
        // отправка события завершения прослушивания текущего элемента
        const isPlaying = getIsPlaying(yield select());
        if (isPlaying) {
            const meta = getMetadata(yield select());
            yield sendStatEvent('listen', null, { meta, });
        }

        const processedMetadata = _changeLinksToAbsoluteInUrls(event.metadata, player.config.metadata.jsonUrl);
        yield put({ type: ActionTypes.METADATA_UPDATED, metadata: processedMetadata });
    }
    else if (event.type === MelonPlayer.Events.ERROR) {
        const isPlaying = getIsPlaying(yield select());
        if (isPlaying) {
            Toaster.show('Ошибка воспроизведения');
        }
    }
    else {
        yield put(event);
    }
}

export function* toggleMute() {
    const state = yield select();
    const isMuted = getIsMuted(state);
    isMuted ? player.mute() : player.unmute();
    if (getVolume(state)) {
        isMuted ? yield sendStatEvent('mute') : yield sendStatEvent('unmute');
    }
}

export function* setVolume() {
    const state = yield select();
    const volume = getVolume(state);
    const station = getSelectedStation(state);
    if (player && ( player.isMuted() || !player.getVolume() )) {
        yield sendStatEvent('unmute');
    }
    else if (!volume) {
        yield sendStatEvent('mute');
    }
    else {
        yield sendStatEvent('change_volume', station.id, { volumeLevel : volume, });
    }
    player && player.setVolume(volume);
    yield saveOptions();
}
