import React from 'react';
import { useSelector } from "react-redux";
import { getLastSelectedStations } from "../../reducers";
import { CentredPage } from "../reused/StyledPrimitives";
import StationList from "../reused/StationList";
import DefaultContent from "../reused/DefaultContent";
import Sandclock from '../../images/sandclock.svg';
import { useRecentStations } from "./stationsListHooks";

export default () => {
    const lastSelectedStations = useSelector(getLastSelectedStations);
    const stations = useRecentStations();

    return (
        lastSelectedStations.length
        ?
        <StationList stations={ stations } />
        :
        <CentredPage>
            <DefaultContent
                icon={Sandclock}
                text="Здесь будут отображаться радиостанции, которые вы слушали недавно"
            />
        </CentredPage>
    );
};