import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Cover } from "./Cover";

const DoubleCoverRoot = styled.div`
    position: relative;
    width: 220px;
    height: 220px;
`;

export default ({ cover = '', onCoverChanged = () => {} }) => {
    const [state, setState] = useState({
        currentCover: '',
        previousCover: '',
        firstIsActive: true,
    });

    useEffect(() => {
        let timeout;
        const img = document.createElement('img');

        const complete = () => {
            setState({
                currentCover: cover,
                previousCover: state.currentCover,
                firstIsActive: !state.firstIsActive,
            });
            onCoverChanged(cover);
        };
        const cancel = () => {
            img.onload = img.onerror = null;
            clearTimeout(timeout);
        };


        timeout = setTimeout(() => {
            cancel();
            complete();
        }, 3000);
        img.onload = img.onerror = complete;
        img.src = cover;

        return cancel;
    }, [cover]);

    return (
        <DoubleCoverRoot>
            <Cover
                src={state.firstIsActive ? state.currentCover : state.previousCover}
                active={state.firstIsActive}
                alt="Обложка текущей композиции"
            />
            <Cover
                src={!state.firstIsActive ? state.currentCover : state.previousCover}
                active={!state.firstIsActive}
                alt="Обложка текущей композиции"
            />
        </DoubleCoverRoot>
    );
};