import React from 'react';
import { withRouter } from 'react-router-dom';
import StationCard from "./StationCard";
import styled from "styled-components";
import Scrollbar from "./Scrollbar";
import { Media } from "../Media";
import MobileStationCard from "./MobileStationCard";

const Stations = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const MobileStations = styled.div`
    padding-top: 16px;
`;

const StationsList = ({ suf = '', stations }) => {
    return (
        <Scrollbar>
            <Media greaterThanOrEqual="tablet">
                <Stations>
                    {
                        stations.map((station, i) => (
                            <StationCard
                                station={ station }
                                key={ station.id }
                                suf={ suf }
                            />
                        ))
                    }
                </Stations>
            </Media>
            <Media lessThan="tablet">
                <MobileStations>
                    {
                        stations.map((station, i) => (
                            <MobileStationCard
                                station={ station }
                                key={ station.id }
                                suf={ suf }
                            />
                        ))
                    }
                </MobileStations>
            </Media>
        </Scrollbar>
    );
};

export default withRouter(StationsList);