import React from 'react';
import { Header, loadingAnimation } from "../reused/StyledPrimitives";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getIsRegionUpdating, getRegions, getRegion, getRegionName, getTheme } from "../../reducers";
import { ActionTypes } from "../../constants";
import RefreshIcon from '../../images/refresh.svg';

const ThemeName = styled.span`
    flex: 0 0 auto;
    width: 50%; // тут а не в flex ради IE
    padding: 0 1em;
    box-sizing: border-box;
    z-index: 1;
    opacity: ${p => p.active ? 1 : 0.8};  
`;

const Highlighter = styled.div`
    background: ${p => p.theme.activeColor};
    width: 50%;
    border-radius: 1000px;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: ${p => p.left ? 0 : 50}%;
    transition: left 0.5s;
`;

const ThemeSwitcherRoot = styled.div`
    cursor: pointer;
    position: relative;
    font-size: 14px;
    display: flex;
    padding: 0.5em;
    box-sizing: border-box;
    border: 1px solid ${p => p.theme.activeColor};
    border-radius: 1000px; 
`;

const TextSwitcher = ({ values, checked = false, onChange = null }) => {
    return (
        <ThemeSwitcherRoot onClick={onChange}>
            <ThemeName active={!checked}>{values[0]}</ThemeName>
            <ThemeName active={checked}>{values[1]}</ThemeName>
            <Highlighter left={!checked} />
        </ThemeSwitcherRoot>
    );
};

const ThemeLabel = styled.div`
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 1em;
`;

const ThemeOption = styled.div`
    font-family: Roboto, sans-serif;
    display: flex;
    align-items: center;
    margin-bottom: 3em;
`;

const RegionLabel = styled.div`
    font-size: 18px;
    display: none;
`;

const RegionContent = styled.div`
    margin-top: 2rem;
    margin-left: 2rem;
    padding-right: 1rem;
    display: none;
`;

const RegionSelect = styled.select`
    font-size: 18px;
    padding: 5px;
    width: 240px;
    height: 34px;
    font-family: inherit;
    background: ${p => p.theme.activeColor};
    color: white;
    border: 1px solid ${p => p.theme.activeColor};
    border-radius: 7px;
    -webkit-appearance: none;

    &:active, &:focus {
        border: 1px solid ${p => p.theme.activeColor};
        outline: none;
    }

    option {
        background-color: ${p => p.theme.mainBackground};
        color: ${p => p.theme.color};
    }
    
    scrollbar-color: ${p => p.theme.activeColor} ${p => p.theme.color};
    scrollbar-width: 1px;
    ::-webkit-scrollbar {
        background-color: ${p => p.theme.color};
        width: 1px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${p => p.theme.activeColor};
    }
`;

const Info = styled.div`
   border-top: 1px solid;
   padding-top: 2rem;
   max-width: 50em;
   margin-top: 2rem;
   font-size: 13px;
   opacity: 0.8;
`;

const Refresh = styled(RefreshIcon)`
    height: 34px;
    cursor: pointer;
    width: 3rem;
    color: ${p => p.theme.activeColor};
    margin-left: 2rem;
    ${p => p.$loading ? loadingAnimation : ''};
`;

export default () => {
    const theme = useSelector(getTheme);
    const dispatch = useDispatch();
    const regions = useSelector(getRegions);
    const region = useSelector(getRegion);
    const isRegionUpdating = useSelector(getIsRegionUpdating);

    const onChangeRegion = e => {
        const regionId = parseInt(e.target.value);
        const region = regions.find(r => r.id === regionId);
        dispatch({ type: ActionTypes.CHANGE_REGION_MANUALLY, payload: region, })
    }

    return (
        <>
            <Header>Настройки</Header>
            <ThemeOption>
                <ThemeLabel>Тема</ThemeLabel>
                <TextSwitcher
                    values={["Темная", "Светлая"]}
                    checked={theme !== 'dark'}
                    onChange={() => dispatch({ type: ActionTypes.TOGGLE_THEME })}
                />
            </ThemeOption>

            <RegionLabel>РЕГИОН</RegionLabel>
            <RegionContent>
                <div css="display: flex">
                    <RegionSelect value={ region ? region.id : 1 } onChange={ onChangeRegion }>
                        {
                            regions.map(r => (
                                <option key={ r.id } value={ r.id }>{ r.name }</option>
                            ))
                        }
                    </RegionSelect>
                    <Refresh
                        title="Обновить"
                        $loading={isRegionUpdating}
                        onClick={() => dispatch({ type: ActionTypes.UPDATE_REGION })}
                    />
                </div>
                <Info>
                    Регион определяется автоматически на основании данных геолокации, предоставленных браузером
                    (если вы дали разрешение получить доступ к вашему местоположению). Если регион определен, то у
                    станций
                    вещание переключается на региональное (при наличие такового в вашем регионе).
                    По умолчанию используется регион "Россия", в котором все станции имеют федеральное вещание.
                </Info>
            </RegionContent>
        </>
    );
}