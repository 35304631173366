import styled from "styled-components";
import { IconWrapper } from "../reused/StyledPrimitives";
import { tabletMinWidth } from "../Media";

export const MenuIconWrapper = styled(IconWrapper)`
    width: 4rem;
    height: 4rem;
    margin-left: 2rem;
    
    @media (max-width: ${tabletMinWidth - 1}px) {
        background: none;
        margin-left: 1em;
    }
`;

export const IconControl = styled.svg`
    opacity: 0.5;  
    flex: 0 0 auto;  
    cursor: pointer;
    
    :hover {
        opacity: 0.8;
    }
`;